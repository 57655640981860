export class LocalLS {
    private static readonly key = 'locale';

    public static getLocale() {
        const userToken = localStorage.getItem(this.key);
        if (userToken) {
            return JSON.parse(userToken) as string;
        }
    }

    public static setLocale(locale: string) {
        localStorage.setItem(this.key, JSON.stringify(locale));
    }

    public static clear() {
        localStorage.removeItem(this.key);
    }
}
