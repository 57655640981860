import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import packageJson from '../package.json';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/Store';
import { theme } from './styles/ant.theme';
import './styles/index.scss';
import './utils/i18n';

console.log('Version: ' + packageJson.version);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider theme={theme}>
                {/* <React.StrictMode> */}
                <App />
                {/* </React.StrictMode> */}
            </ConfigProvider>
        </BrowserRouter>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
