import { ICurrencyData } from '@api/mainServiceAPI';

export interface ITransferState {
    amountSend: number;
    amountReceive: number;
    sendCurrencyMoney: ICurrencyData | undefined;
    receiveCurrencyMoney: ICurrencyData | undefined;
    commission: number;
    currencyRate: number;
}

export const initialTransferState: ITransferState = {
    amountSend: 1000,
    amountReceive: 0,
    sendCurrencyMoney: undefined,
    receiveCurrencyMoney: undefined,
    commission: 4,
    currencyRate: 0,
};
