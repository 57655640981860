import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { ICoreState, initialCoreState } from './state';

// Slice
export const coreSlice = createSlice({
    name: 'core',
    initialState: initialCoreState,
    reducers: {
        setCurrentLanguage: (state: ICoreState, action: PayloadAction<string>) => {
            state.currentLanguage = action.payload;
        },

        setGlobalLoading: (state: ICoreState, action: PayloadAction<boolean>) => {
            state.globalLoading = action.payload;
        },
    },
});

// Actions
export const { setGlobalLoading, setCurrentLanguage } = coreSlice.actions;

// Reducer
export const coreReducer = coreSlice.reducer;

// Selectors
export const selectCurrentLanguage = (state: RootState) => state.coreReducer.currentLanguage;
export const selectGlobalLoading = (state: RootState) => state.coreReducer.globalLoading;
