import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

export interface ICurrencyData {
    alfa_code3: string;
    char_code: string;
    flag_send: boolean;
    flag_withdraw: boolean;
    name: string;
    country: string;
}

export interface IPaymentDTO {
    char_code: string;
    country_code: string;
    currency_name: string;
    payment_method: string;
    display: boolean;
    display_name: string;
}

export const getCurrencyData = <TData = AxiosResponse<ICurrencyData[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/transaction/currency`, options);
};

export const getPaymentMethods = <TData = AxiosResponse<IPaymentDTO[]>>(charCode: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/transaction/payment/method/${charCode}`, options);
};
