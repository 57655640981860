import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Footer } from 'src/pages/_general/Footer/Footer';
import { Header } from 'src/pages/_general/Header/Header';
import styles from './AnonymousLayout.module.scss';

export const AnonymousLayout = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <div className="sticky-top">
                <Header />
            </div>

            <section className={styles.mainContent}>
                <Outlet />
            </section>

            <Footer />
        </div>
    );
};
