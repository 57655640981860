import { ICurrencyData, IPaymentDTO, getCurrencyData, getPaymentMethods } from '@api/mainServiceAPI';
import { ICheckLimitResponse, checkLimits, setExchangeCalc, setUserTransactionCheck } from '@api/paymentServiceAPI';
import { ChevronDownBlackIcon, CircleIcon, FilledCircleIcon } from '@icons';
import { selectAuthUser } from '@sliceUser';
import { KycStatuses } from '@utils/constants';
import { Button, Col, Dropdown, Input, Modal, Row, message } from 'antd';
import classNames from 'classnames';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
    selectCommission,
    selectCurrencyRate,
    selectReceiveAmount,
    selectReceiveCurrency,
    selectSendAmount,
    selectSendCurrency,
    setAmountReceive,
    setAmountSend,
    setCommission,
    setCurrencyRate,
    setReceiveCurrency,
    setSendCurrency,
} from 'src/store/transfer';
import styles from './HomePage.module.scss';

export const HomePageCountries = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const name = searchParams.get('name');
    const sendCurrencyURL = searchParams.get('sendCurrency');
    const receiveCurrencyURL = searchParams.get('receiveCurrency');

    const sendAmount = useSelector(selectSendAmount);
    const receiveAmount = useSelector(selectReceiveAmount);
    const sendCurrency = useSelector(selectSendCurrency);
    const receiveCurrency = useSelector(selectReceiveCurrency);
    const commission = useSelector(selectCommission);
    const currencyRate = useSelector(selectCurrencyRate);
    const authUser = useSelector(selectAuthUser);

    const [receiveCurrencyList, setReceiveCurrencyList] = useState<ICurrencyData[]>([]);
    const [sendCurrencyList, setSendCurrencyList] = useState<ICurrencyData[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [limits, setLimits] = useState<ICheckLimitResponse>();
    const [listOfCountries, setListOfCountries] = useState<ICurrencyData[]>([]);
    const [listOfPaymentMethods, setListOfPaymentMethods] = useState<IPaymentDTO[]>([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TMBCB888');
`;
        document.head.appendChild(script);
        window.scrollTo(0, 0);
        getCurrencyData().then((currencyRes) => {
            const currencyData = currencyRes.data;

            const sendDefaultCurrencyList = currencyData.filter((item) => item.flag_withdraw);

            const receiveDefaultCurrencyList = currencyData.filter((item) => item.flag_send);

            const defaultSendCurrency = sendDefaultCurrencyList.find((cur) => cur.char_code === sendCurrencyURL) || sendDefaultCurrencyList[0];

            const defaultReceiveCurrency =
                receiveDefaultCurrencyList.find((cur) => cur.char_code === receiveCurrencyURL) || receiveDefaultCurrencyList[0];

            setListOfCountries(receiveDefaultCurrencyList.sort((a: ICurrencyData, b: ICurrencyData) => a.country.localeCompare(b.country)));
            setSendCurrencyList(sendDefaultCurrencyList);
            setReceiveCurrencyList(receiveDefaultCurrencyList);
            dispatch(setSendCurrency(defaultSendCurrency));
            dispatch(setReceiveCurrency(defaultReceiveCurrency));
            onCheckLimits(defaultSendCurrency.char_code);

            if (sendAmount && sendAmount > 0) {
                onExchangeCalc(sendAmount, defaultSendCurrency.char_code, defaultReceiveCurrency.char_code);
            }
        });
        getPaymentMethods(receiveCurrencyURL as string).then((res) => {
            setListOfPaymentMethods(res.data);
        });
    }, [name]);

    const handleSelectSendCurrency = (currency: ICurrencyData) => {
        dispatch(setSendCurrency(currency));
        onExchangeCalc(sendAmount, currency.char_code, receiveCurrency!.char_code);
        onCheckLimits(currency.char_code, sendAmount);
    };
    const handleSelectReceiveCurrency = (currency: ICurrencyData) => {
        dispatch(setReceiveCurrency(currency));
        onExchangeCalc(sendAmount, sendCurrency!.char_code, currency.char_code);
    };

    const handleChangeSendAmount = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            const amount = parseInt(e.target.value);
            dispatch(setAmountSend(amount));
            onExchangeCalc(amount, sendCurrency!.char_code, receiveCurrency!.char_code);
            onCheckLimits(sendCurrency!.char_code, amount);
        } else {
            const amount = 0;
            dispatch(setAmountSend(amount));
            dispatch(setAmountReceive(0));
        }
    };

    const onExchangeCalc = (amount: number, sendCurrency: string, receiveCurrency: string) => {
        setExchangeCalc({
            fiat_amount: amount,
            fiat_currency: sendCurrency,
            recipient_currency: receiveCurrency,
        }).then((result) => {
            dispatch(setAmountReceive(result.data.total));
            dispatch(setCommission(result.data.commission));
            dispatch(setCurrencyRate(result.data.rate));
        });
    };

    const onCheckLimits = (sendCurrency: string, amount?: number) => {
        checkLimits({
            fiat_currency: sendCurrency,
            fiat_amount: amount,
        }).then((result) => {
            setLimits(result.data);
        });
    };

    const handleStart = () => {
        if (sendAmount >= limits!.min && sendAmount <= limits!.max) {
            if (authUser) {
                if (authUser?.is_full_registered) {
                    if (!authUser?.is_kyc_completed) {
                        setUserTransactionCheck({
                            fiat_amount: sendAmount,
                            fiat_currency: sendCurrency?.char_code || 'USD',
                            redirect_url: '',
                            recipient_currency: receiveCurrency?.char_code || 'EGP',
                        }).then((res) => {
                            const statuses = res.data.kyc_status;
                            if (statuses.length === 0 && !res.data.redirect_url) {
                                return navigate('/transfer');
                            }
                            if (statuses !== KycStatuses.FAILED && statuses.length !== 0) {
                                message.warning('Contact to Q-Send support team');
                                return;
                            }
                            if (statuses === KycStatuses.UNDER_REVIEW) {
                                message.success('Your KYC is under review, try again later');
                                return;
                            }
                            if (res.data.redirect_url) {
                                window.location.href = res.data.redirect_url;
                            }
                        });
                    } else {
                        return navigate('/transfer');
                    }
                } else {
                    return setShowModal(true);
                }
            } else {
                navigate('/login');
            }
        } else {
            message.error(t('homePage.calculatorBanner.checkLimits'));
        }
    };

    const chunk = (arr: ICurrencyData[], size: number) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

    const groupedCountries = chunk(listOfCountries, 8);

    const handleError = (e: SyntheticEvent<HTMLImageElement>) => {
        const target = e.currentTarget as HTMLImageElement;
        target.src = '/img/countries/bolivia.jpeg';
    };

    return (
        <>
            <Helmet>
                <title>{t('metaTags.homePageCountries.title', { country: name })}</title>
                <meta name="description" content={t('metaTags.homePageCountries.description', { country: name })} />
                <meta name="keywords" content={t('metaTags.homePageCountries.keywords')} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>
            <div className={styles.wrapper}>
                {showModal && (
                    <Modal width={480} closable={false} onCancel={() => setShowModal(false)} closeIcon={null} open={showModal} footer={null}>
                        <div className={styles.modalWrapper}>
                            <p className={styles.modalTitle}>{t('homePage.modal.title')}</p>
                            <p className="text-center">{t('homePage.modal.description')}</p>
                            <Button onClick={() => navigate('/profile')} className={styles.checkButton} type="primary">
                                {t('homePage.modal.button')}
                            </Button>
                        </div>
                    </Modal>
                )}
                <div className={styles['calculator-container']}>
                    <Row align="middle" justify="space-between">
                        <div className={styles.imgContainer}>
                            <img
                                className={styles.landscape}
                                src={`/img/countries/${name?.toLowerCase()}.jpeg`}
                                onError={handleError}
                                alt="landscape"
                            />
                        </div>
                        <Col lg={13} sm={24}>
                            <h1 className={styles.title}>{t('homePage.countries.title', { name: name })}</h1>
                            <h2 className={styles.subtitle}>{t('homePage.countries.subtitle', { name: name })}</h2>
                            <Row gutter={13}>
                                <Col lg={8} md={12}>
                                    <div className="d-flex align-items-center">
                                        <img width={80} src="/img/laptop.png" alt="laptop" />
                                        <p className={styles['img-description']}>{t('homePage.countries.fastHassle')}</p>
                                    </div>
                                </Col>
                                <Col lg={8} md={12}>
                                    <div className="d-flex align-items-center">
                                        <img width={80} src="/img/chat.png" alt="laptop" />
                                        <p className={styles['img-description']}>{t('homePage.countries.support')}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={styles.calcWrapper} lg={7} sm={24}>
                            <div className={styles['calc-banner']}>
                                <p className={styles.label}>{t('homePage.calculatorBanner.sendAmountLabel')}</p>
                                <div className={styles.inputWrapper}>
                                    <Input onChange={handleChangeSendAmount} value={sendAmount} type="number" />
                                    <Dropdown
                                        trigger={['click']}
                                        menu={{
                                            items: sendCurrencyList.map((s) => ({
                                                key: s.char_code,
                                                label: (
                                                    <a onClick={() => handleSelectSendCurrency(s)} className={styles['dropdown-item']}>
                                                        <div className={classNames('me-2', `flag-icon flag-icon-${s.alfa_code3.toLowerCase()}`)} />
                                                        {s.char_code}
                                                    </a>
                                                ),
                                            })),
                                        }}
                                    >
                                        <a className={styles.dropdownText}>
                                            <div className={classNames('me-2', `flag-icon flag-icon-${sendCurrency?.alfa_code3.toLowerCase()}`)} />
                                            {sendCurrency?.char_code}
                                            <ChevronDownBlackIcon className="ms-1" />
                                        </a>
                                    </Dropdown>
                                </div>
                                <p className={styles.label}>{t('homePage.calculatorBanner.receiveAmountLabel')}</p>
                                <div className={styles.inputWrapper}>
                                    <Input disabled value={receiveAmount} type="number" />
                                    <Dropdown
                                        trigger={['click']}
                                        menu={{
                                            items: receiveCurrencyList.map((s) => ({
                                                key: s.char_code,
                                                label: (
                                                    <a onClick={() => handleSelectReceiveCurrency(s)} className={styles['dropdown-item']}>
                                                        <div className={classNames('me-2', `flag-icon flag-icon-${s.alfa_code3.toLowerCase()}`)} />
                                                        {s.char_code}
                                                    </a>
                                                ),
                                            })),
                                        }}
                                    >
                                        <a className={styles.dropdownText}>
                                            <div className={classNames('me-2', `flag-icon flag-icon-${receiveCurrency?.alfa_code3.toLowerCase()}`)} />
                                            {receiveCurrency?.char_code}
                                            <ChevronDownBlackIcon className="ms-1" />
                                        </a>
                                    </Dropdown>
                                </div>
                                <Button disabled={sendAmount === 0} onClick={handleStart} type="primary" className={styles['send-button']}>
                                    {t('homePage.calculatorBanner.startTransferBtn')}
                                </Button>
                                <p className={styles.label}>
                                    {t('homePage.calculatorBanner.currentCurrencyRate', {
                                        receiveCurrency: receiveCurrency?.char_code,
                                        sendCurrency: sendCurrency?.char_code,
                                    })}
                                    : {currencyRate}
                                </p>
                                <p className={styles.label}>
                                    {t('homePage.calculatorBanner.commission')}: {commission}%
                                </p>
                                <p className={styles.label}>
                                    {t('homePage.calculatorBanner.limitMax')} {limits?.max} {sendCurrency?.char_code}
                                </p>
                                <p className={styles.label}>
                                    {t('homePage.calculatorBanner.limitMin')} {limits?.min} {sendCurrency?.char_code}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.methods}>
                    <p className={styles.title}>{t('homePage.countries.availableDeliveryMethod.title', { country: name })}</p>
                    <p className={styles.subtitle}>{t('homePage.countries.availableDeliveryMethod.subtitle')}</p>
                    <div className={styles['methods-description']}>
                        <Row gutter={80}>
                            <Col lg={8}>
                                <p className={styles.title}>{t('homePage.countries.availableDeliveryMethod.toBankCard.title')}</p>
                                <p className={styles.description}>
                                    {t('homePage.countries.availableDeliveryMethod.toBankCard.description', {
                                        currency: receiveCurrencyURL,
                                        country: name,
                                    })}
                                </p>
                            </Col>
                            <Col lg={8}>
                                <p className={styles.title}>{t('homePage.countries.availableDeliveryMethod.toBankAccounts.title')}</p>
                                <p className={styles.description}>
                                    {t('homePage.countries.availableDeliveryMethod.toBankAccounts.description', {
                                        country: name,
                                    })}{' '}
                                    {listOfPaymentMethods.map((method) => method.payment_method).join(', ')}
                                </p>
                            </Col>
                            <Col lg={8}>
                                <p className={styles.title}>{t('homePage.countries.availableDeliveryMethod.toWallets.title')}</p>
                                <p className={styles.description}>
                                    {t('homePage.countries.availableDeliveryMethod.toWallets.description', {
                                        currency: receiveCurrencyURL,
                                        country: name,
                                    })}
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={styles.banners}>
                    <div className={styles.advantages}>
                        <Row gutter={20} align="stretch">
                            <Col lg={10} sm={24}>
                                <Row className={classNames(styles.advantage)}>
                                    <Col className={styles.advantageCol} span={14}>
                                        <div>
                                            <p className={styles.advantageTitle}>{t('homePage.banners.globalBanner.title')}</p>
                                            <p className={styles.advantageDesc}>{t('homePage.banners.globalBanner.subtitle')}</p>
                                        </div>
                                    </Col>
                                    <Col span={10}>
                                        <img className={styles.smallImg} src="/img/laptop.png" alt="laptop" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={14} sm={24}>
                                <Row className={classNames(styles.advantage)}>
                                    <Col className={styles.advantageCol} span={14}>
                                        <div>
                                            <p className={styles.advantageTitle}>{t('homePage.banners.fixCommissionBanner.title')}</p>
                                            <p className={styles.advantageDesc}>{t('homePage.banners.fixCommissionBanner.subtitle')}</p>
                                        </div>
                                    </Col>
                                    <Col span={10}>
                                        <img className={styles.smallImg} src="/img/fix.png" alt="graphic" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.advantages}>
                        <Row gutter={20} align="stretch">
                            <Col lg={14} sm={24}>
                                <Row className={classNames(styles.advantage)}>
                                    <Col className={styles.advantageCol} span={16}>
                                        <div>
                                            <p className={styles.advantageTitle}>{t('homePage.banners.supportBanner.title')}</p>
                                            <p className={styles.advantageDesc}>{t('homePage.banners.supportBanner.subtitle')}</p>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <img className={styles.smallImg} src="/img/chat.png" alt="chat" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={10} sm={24}>
                                <Row className={classNames(styles.advantage)}>
                                    <Col className={styles.advantageCol} span={15}>
                                        <p className={styles.advantageTitle}>{t('homePage.banners.quickTransferBanner.title')}</p>
                                        <p className={styles.advantageDesc}>{t('homePage.banners.quickTransferBanner.subtitle')}</p>
                                    </Col>
                                    <Col span={9}>
                                        <img className={styles.smallImg} src="/img/time.png" alt="clock" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={styles['supported-countries']}>
                    <p className={styles.title}>{t('homePage.countries.supportCountries.title')}</p>
                    <p className={styles.subtitle}>{t('homePage.countries.supportCountries.subtitle', { number: listOfCountries?.length })}.</p>
                    <div className={styles['countries-container']}>
                        <Row justify="space-between">
                            {groupedCountries?.map((group, idx) => (
                                <Col lg={6} md={12} sm={24} key={`group-${idx}`}>
                                    {group?.map((item, i) => (
                                        <div className="d-flex align-items-center mb-4 country-item" key={`country-${i}`}>
                                            <div
                                                className={classNames(
                                                    'me-2',
                                                    `flag-icon flag-icon-${item?.alfa_code3.toLowerCase()}`,
                                                    styles['round-flag'],
                                                )}
                                            />
                                            <NavLink
                                                to={`/send-money/?name=${item.country}&sendCurrency=USD&receiveCurrency=${item.char_code}`}
                                                className={styles.description}
                                            >
                                                {item.country}
                                            </NavLink>
                                        </div>
                                    ))}
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
                <div className={styles['answer-container']}>
                    <Row justify="space-between" align="top">
                        <Col md={7} sm={24}>
                            <p className={styles.title}>{t('homePage.questionAnswerBlock.transferQuestion')}</p>
                            <Button onClick={handleStart} className={styles.buttonBig} type="primary">
                                {t('homePage.questionAnswerBlock.startTransferBtn')}
                            </Button>
                        </Col>
                        <Col md={12} sm={24}>
                            <div className={styles.ruleGroup}>
                                <div className={styles.progressGroup}>
                                    <FilledCircleIcon />
                                    <div className={styles.verticalDivider} />
                                </div>
                                <div className={styles.rule}>
                                    <p className={styles.ruleTitle}>
                                        <p className={styles.number}>01.</p> {t('homePage.questionAnswerBlock.registerRule.title')}
                                    </p>
                                    <p className={styles.ruleDesc}>{t('homePage.questionAnswerBlock.registerRule.description')}</p>
                                </div>
                            </div>
                            <div className={styles.ruleGroup}>
                                <div className={styles.progressGroup}>
                                    <CircleIcon />
                                    <div className={styles.greyVerticalDivider} />
                                </div>
                                <div className={styles.rule}>
                                    <p className={styles.ruleTitle}>
                                        <p className={styles.number}>02.</p>
                                        {t('homePage.questionAnswerBlock.orderRule.title')}
                                    </p>
                                    <p className={styles.ruleDesc}>{t('homePage.questionAnswerBlock.orderRule.description')}</p>
                                </div>
                            </div>
                            <div className={styles.ruleGroup}>
                                <div className={styles.progressGroup}>
                                    <CircleIcon />
                                    <div className={styles.greyVerticalDivider} />
                                </div>
                                <div className={styles.rule}>
                                    <p className={styles.ruleTitle}>
                                        <p className={styles.number}>03.</p>
                                        {t('homePage.questionAnswerBlock.partnerRule.title')}
                                    </p>
                                    <p className={styles.ruleDesc}>{t('homePage.questionAnswerBlock.partnerRule.description')}</p>
                                </div>
                            </div>
                            <div className={styles.ruleGroup}>
                                <div className={styles.progressGroup}>
                                    <CircleIcon />
                                </div>
                                <div className={styles.rule}>
                                    <p className={styles.ruleTitle}>
                                        <p className={styles.number}>04.</p>
                                        {t('homePage.questionAnswerBlock.correctRule.title')}
                                    </p>
                                    <p className={styles.ruleDesc}>{t('homePage.questionAnswerBlock.correctRule.description')}</p>
                                </div>
                            </div>
                            <Button onClick={handleStart} className={styles.buttonSmall} type="primary">
                                {t('homePage.questionAnswerBlock.startTransferBtn')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
