//eslint-disable-next-line
export const PHONE_VALIDATION = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

// Transaction statuses:
export enum TransferStatusesEnums {
    PROCESSING = 'processing',
    CANCELED = 'cancelled',
    PAID = 'paid',
    REJECTED = 'rejected',
    FAILED = 'failed',
    WITHDRAWAL_COMPLETED = 'withdrawal completed',
    FINISHED = 'finished',
}

//payment types
export enum PaymentTypesEnum {
    CARD = 'card',
    IABN = 'iban',
}

// kyc statuses
export enum KycStatuses {
    COMPLETE = 'complete', // Процедуры KYC успешно завершены.
    INCOMPLETE = 'incomplete', //SumSub не начал проверку.
    FAILED_ATTEMPT = 'failed_attempt', //первая попытка пройти проверку не удалась. Попробуйте еще раз.
    FAILED = 'failed', //проверка не удалась; пользователю не разрешено создавать IBAN. Обратитесь в службу поддержки Mercuryo.
    UNDER_REVIEW = 'under_review', // SumSub проверяет документы
}
