import { ICurrencyData, IPaymentDTO, getCurrencyData, getPaymentMethods } from '@api/mainServiceAPI';
import { setExchangeCalc, setTransaction, validCard, validIban } from '@api/paymentServiceAPI';
import { getUserOrder } from '@api/userServiceAPI';
import { ChevronDownBlackIcon, TimerIcon } from '@icons';
import { setToken } from '@sliceUser';
import { PaymentTypesEnum } from '@utils/constants';
import { Button, Checkbox, Dropdown, Form, Input, Progress, Select, Switch } from 'antd';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import {
    selectCommission,
    selectCurrencyRate,
    selectReceiveAmount,
    selectReceiveCurrency,
    selectSendAmount,
    selectSendCurrency,
    setAmountReceive,
    setAmountSend,
    setCommission,
    setCurrencyRate,
    setReceiveCurrency,
    setSendCurrency,
} from 'src/store/transfer';
import styles from './TransferPage.module.scss';

interface IFormValuesProps {
    recipient_name: string;
    recipient_surname: string;
    recipient_phone: string;
    recipient_card?: string;
    iban?: string;
    payment_method: string;
}

export const TransferPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);
    const navigate = useNavigate();
    const [keepWatching, setKeepWatching] = useState(true);
    // const { showWarningModal, handleOk, handleCancel } = usePrompt(t('transferPage.breakTransaction'), keepWatching);

    const sendAmount = useSelector(selectSendAmount);
    const receiveAmount = useSelector(selectReceiveAmount);
    const sendCurrency = useSelector(selectSendCurrency);
    const receiveCurrency = useSelector(selectReceiveCurrency);
    const commission = useSelector(selectCommission);
    const currencyRate = useSelector(selectCurrencyRate);

    const [receiveCurrencyList, setReceiveCurrencyList] = useState<ICurrencyData[]>([]);
    const [sendCurrencyList, setSendCurrencyList] = useState<ICurrencyData[]>([]);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [initialValues, setInitialValues] = useState<IFormValuesProps>({
        recipient_name: '',
        recipient_surname: '',
        recipient_phone: '',
        recipient_card: '',
        payment_method: '',
    });
    const [promoCode, setPromoCode] = useState<string | undefined>();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentDTO>();
    const [listOfPaymentMethods, setListOfPaymentMethods] = useState<IPaymentDTO[]>([]);
    const [agreement, setAgreement] = useState<boolean>(true);
    const [orderLink, setOrderLink] = useState<string>('');
    const [idClient, setIdClient] = useState<number>();
    const [cardNumber, setCardNumber] = useState<string>('');
    const [widgetLink, setWidgetLink] = useState<string>('');
    const [isIban, setIsIban] = useState<boolean>(false);
    const [iban, setIban] = useState<string>('');

    useEffect(() => {
        if (currentStep > 2) {
            setKeepWatching(false);
        } else {
            setKeepWatching(true);
        }
    }, [currentStep]);

    useEffect(() => {
        if (receiveCurrency) {
            getPaymentMethods(receiveCurrency?.char_code || '').then((res) => {
                setListOfPaymentMethods(res.data);
            });
        }
        getCurrencyData().then((currencyRes) => {
            const currencyData = currencyRes.data;

            const sendDefaultCurrencyList = currencyData.filter((item) => item.flag_withdraw);

            const receiveDefaultCurrencyList = currencyData.filter((item) => item.flag_send);

            setSendCurrencyList(sendDefaultCurrencyList);
            setReceiveCurrencyList(receiveDefaultCurrencyList);
            const params = new URLSearchParams(window.location.search);
            const token = params.get('token');
            const fiatAmount = params.get('fiatAmount');
            const fiatCurrency = params.get('fiatCurrency');
            const recipientCurrency = params.get('recipientCurrency');
            if (token && fiatAmount && fiatCurrency && recipientCurrency) {
                setToken(token);
                dispatch(setAmountSend(+fiatAmount));
                dispatch(setSendCurrency(sendDefaultCurrencyList.find((cur) => cur.char_code === fiatCurrency)));
                dispatch(setReceiveCurrency(receiveDefaultCurrencyList.find((cur) => cur.char_code === recipientCurrency)));
                getPaymentMethods(receiveDefaultCurrencyList.find((cur) => cur.char_code === recipientCurrency)!.char_code).then((res) => {
                    setListOfPaymentMethods(res.data);
                });

                onExchangeCalc(
                    sendAmount,
                    sendDefaultCurrencyList.find((cur) => cur.char_code === fiatCurrency)!.char_code,
                    receiveDefaultCurrencyList.find((cur) => cur.char_code === recipientCurrency)!.char_code,
                );
                return;
            }

            if (sendCurrency === null && receiveCurrency === null) {
                const defaultSendCurrency = sendDefaultCurrencyList.find((cur) => cur.char_code === 'USD') || sendDefaultCurrencyList[0];
                const defaultReceiveCurrency = receiveDefaultCurrencyList.find((cur) => cur.char_code === 'EGP') || receiveDefaultCurrencyList[0];
                dispatch(setReceiveCurrency(defaultReceiveCurrency));
                dispatch(setSendCurrency(defaultSendCurrency));
                dispatch(setAmountSend(1000));
                getPaymentMethods(defaultReceiveCurrency?.char_code || 'EGP').then((res) => {
                    setListOfPaymentMethods(res.data);
                });

                onExchangeCalc(sendAmount, defaultSendCurrency!.char_code, defaultReceiveCurrency!.char_code);
                return;
            }
        });
    }, []);

    const handleSelectSendCurrency = (currency: ICurrencyData) => {
        dispatch(setSendCurrency(currency));
        onExchangeCalc(sendAmount, currency.char_code, receiveCurrency!.char_code, promoCode);
    };
    const handleSelectReceiveCurrency = (currency: ICurrencyData) => {
        dispatch(setReceiveCurrency(currency));
        onExchangeCalc(sendAmount, sendCurrency!.char_code, currency.char_code, promoCode);
        getPaymentMethods(currency.char_code || '').then((res) => {
            setListOfPaymentMethods(res.data);
        });
    };

    const maskCardNumber = (cardNumber: string) => {
        const firstFour = cardNumber.substring(0, 4);
        const lastFour = cardNumber.substring(cardNumber.length - 4);

        return `${firstFour}******${lastFour}`;
    };

    const handleChangeSendAmount = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            const amount = parseInt(e.target.value);
            dispatch(setAmountSend(amount));
            onExchangeCalc(amount, sendCurrency!.char_code, receiveCurrency!.char_code, promoCode);
        } else {
            const amount = 0;
            dispatch(setAmountSend(amount));
            dispatch(setAmountReceive(0));
        }
    };

    const onExchangeCalc = (amount: number, sendCurrency: string, receiveCurrency: string, promoCode?: string) => {
        setExchangeCalc({
            fiat_amount: amount,
            fiat_currency: sendCurrency,
            recipient_currency: receiveCurrency,
            promo_code: promoCode,
        }).then((result) => {
            dispatch(setAmountReceive(result.data.total));
            dispatch(setCommission(result.data.commission));
            dispatch(setCurrencyRate(result.data.rate));
        });
    };

    const handleStart = () => {
        setCurrentStep(currentStep + 1);
        handleAgree();
    };

    const handleChangeStep = () => {
        setCurrentStep(currentStep + 1);
        setInitialValues({ ...form.getFieldsValue() });
    };

    const submit = (formValues: IFormValuesProps) => {
        // setInitialValues({ ...formValues });
        setInitialValues((prev) => ({ ...prev, formValues }));
        if (formValues) {
            setTransaction({
                account_type: isIban ? PaymentTypesEnum.IABN : PaymentTypesEnum.CARD,
                fiat_amount: sendAmount,
                fiat_currency: sendCurrency!.char_code!,
                payment_method: initialValues.payment_method,
                recipient_amount: receiveAmount,
                recipient_currency: receiveCurrency!.char_code,
                recipient_name: initialValues.recipient_name,
                recipient_surname: initialValues.recipient_surname,
                recipient_phone: formValues.recipient_phone,
                recipient_card: formValues.recipient_card ? formValues.recipient_card.replace(/\s/g, '') : undefined,
                iban: formValues.iban ? formValues.iban : undefined,
                promo_code: promoCode,
            }).then((res) => {
                setCurrentStep(currentStep + 1);
                window.location.href = res.data?.redirect_url || '';
                setWidgetLink(res.data?.redirect_url);
                setInitialValues({ ...formValues });
                setIdClient(res.data.id_client);
            });
        }
    };

    const validate = () => {
        const fieldsToCheck = ['recipient_name', 'recipient_surname', 'recipient_phone', 'recipient_card', 'payment_method'];

        validateForm(fieldsToCheck, () => {
            const additionalFieldsToCheck = ['agreement', 'acceptPersonalDataProcessing', 'acceptPrivacyPolicy'];
            return Object.values(form.getFieldsValue(additionalFieldsToCheck)).includes(false);
        });
    };
    const handleNavigateHome = () => {
        navigate('/');
    };

    const handleChangePhoneNumber = (e: string) => {
        form.setFieldValue('recipient_phone', e);
        setInitialValues({ ...initialValues, recipient_phone: e });
    };

    const handleChangePromoCode = (e: ChangeEvent<HTMLInputElement>) => {
        setPromoCode(e.target.value);
        onExchangeCalc(sendAmount, sendCurrency!.char_code, receiveCurrency!.char_code, e.target.value);
    };

    const handleAgree = () => {
        setAgreement(true);

        getUserOrder({
            fiat_amount: sendAmount,
            fiat_currency: sendCurrency!.char_code,
            promo_code: promoCode,
        }).then((res) => {
            setOrderLink(res.data);
            setAgreement(true);
        });
    };

    const handleLink = () => {
        if (agreement) {
            window.location.href = orderLink;
        } else {
            window.open(orderLink, '_blank');
        }
    };

    const handleCardChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove all non-digits
        if (value.length > 16) {
            value = value.substr(0, 16); // Max length of 16 digits
        }

        const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim();
        setCardNumber(formattedValue);
        form.setFieldValue('recipient_card', formattedValue);
    };

    return (
        <>
            <Helmet>
                <title>Q-Send Transfer</title>
                <meta content="." name="description" />
            </Helmet>
            <div className={styles.wrapper}>
                {/* <Modal
                title={<p className={styles.modalTitle}>{t('transferPage.breakTransaction')}</p>}
                width={590}
                open={showWarningModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="support" className="w-100" onClick={handleCancel}>
                        {t('transferPage.cancelButton')}
                    </Button>,
                    <Button className="w-100" onClick={handleOk} key="submit" type="primary">
                        {t('transferPage.endLater')}
                    </Button>,
                ]}
            >
                <div className={styles.modal}>
                    <div className="text-center">
                        <p className={styles.description}> {t('transferPage.areYouSure')}</p>
                    </div>
                </div>
            </Modal> */}
                <div className={styles.card}>
                    {currentStep === 0 && (
                        <div className={styles.mobileCard}>
                            <p className={styles.title}>{t('transferPage.title')}</p>
                            <div className={styles.container}>
                                <p className={styles.label}>{t('homePage.calculatorBanner.sendAmountLabel')}</p>
                                <div className={styles.inputWrapper}>
                                    <Input onChange={handleChangeSendAmount} type="number" value={sendAmount} />
                                    <Dropdown
                                        trigger={['click']}
                                        menu={{
                                            items: sendCurrencyList.map((s) => ({
                                                key: s.char_code,
                                                label: (
                                                    <a onClick={() => handleSelectSendCurrency(s)} className={styles['dropdown-item']}>
                                                        <div className={classNames('me-2', `flag-icon flag-icon-${s.alfa_code3.toLowerCase()}`)} />
                                                        {s.char_code}
                                                    </a>
                                                ),
                                            })),
                                        }}
                                    >
                                        <a className={styles.dropdownText}>
                                            <div className={classNames('me-2', `flag-icon flag-icon-${sendCurrency?.alfa_code3.toLowerCase()}`)} />
                                            {sendCurrency?.char_code}
                                            <ChevronDownBlackIcon className="ms-1" />
                                        </a>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className={styles.container}>
                                <p className={styles.label}>{t('homePage.calculatorBanner.receiveAmountLabel')}</p>
                                <div className={styles.inputWrapper}>
                                    <Input disabled value={receiveAmount} type="number" />
                                    <Dropdown
                                        trigger={['click']}
                                        menu={{
                                            items: receiveCurrencyList.map((s) => ({
                                                key: s.char_code,
                                                label: (
                                                    <a onClick={() => handleSelectReceiveCurrency(s)} className={styles['dropdown-item']}>
                                                        <div className={classNames('me-2', `flag-icon flag-icon-${s.alfa_code3.toLowerCase()}`)} />
                                                        {s.char_code}
                                                    </a>
                                                ),
                                            })),
                                        }}
                                    >
                                        <a className={styles.dropdownText}>
                                            <div className={classNames('me-2', `flag-icon flag-icon-${receiveCurrency?.alfa_code3.toLowerCase()}`)} />
                                            {receiveCurrency?.char_code}
                                            <ChevronDownBlackIcon className="ms-1" />
                                        </a>
                                    </Dropdown>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('transferPage.labels.promoCode')}</p>

                                    <Input onChange={handleChangePromoCode} value={promoCode} />
                                </div>
                                <Button onClick={handleStart} type="primary" className={styles['send-button']}>
                                    {t('homePage.calculatorBanner.startTransferBtn')}
                                </Button>
                                <div className={styles.currencyRule}>
                                    <p className={styles.label}>
                                        {t('homePage.calculatorBanner.currentCurrencyRate', {
                                            receiveCurrency: receiveCurrency?.char_code,
                                            sendCurrency: sendCurrency?.char_code,
                                        })}
                                        : {currencyRate}
                                    </p>
                                    <p className={styles.label}>
                                        {t('homePage.calculatorBanner.commission')}: {commission}%
                                    </p>
                                </div>
                            </div>
                            <div className={styles.divider} />
                            <div className={styles.container}>
                                <p className={styles.rules}>{t('transferPage.rule')}</p>
                            </div>
                        </div>
                    )}
                    {currentStep !== 0 && currentStep !== 3 && (
                        <>
                            <p className={styles.participantTitle}>{t('transferPage.participantTitle')}</p>
                            <p className={styles.subtitle}>
                                {t('transferPage.sendAmount', { receiveAmount: receiveAmount, receiveCurrency: receiveCurrency?.char_code })}
                            </p>
                            <p className={styles.step}>{t('transferPage.steps', { step: currentStep })}</p>
                            <Progress className={styles.progress} showInfo={false} percent={currentStep * (100 / 2)} steps={2} />

                            <Form
                                form={form}
                                name="transfer"
                                wrapperCol={{ span: 24 }}
                                initialValues={initialValues}
                                autoComplete="off"
                                onFinish={currentStep === 2 ? submit : handleChangeStep}
                                className="w-100"
                                onChange={validate}
                            >
                                {currentStep === 1 && (
                                    <>
                                        <div className={styles.formItem}>
                                            <p className={styles.label}>{t('transferPage.labels.name')}</p>
                                            <Form.Item
                                                name="recipient_name"
                                                rules={[
                                                    {
                                                        pattern: /^[a-zA-Z]+$/,
                                                        message: t('rules.fillInLatin'),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input autoFocus />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.formItem}>
                                            <p className={styles.label}>{t('transferPage.labels.lastName')}</p>
                                            <Form.Item
                                                name="recipient_surname"
                                                rules={[
                                                    {
                                                        pattern: /^[a-zA-Z]+$/,
                                                        message: t('rules.fillInLatin'),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        {listOfPaymentMethods.length > 0 && (
                                            <div className={styles.formItem}>
                                                <p className={styles.label}>{t('transferPage.labels.obtainMethod')}</p>
                                                <Form.Item name="payment_method" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                                    <Select
                                                        value={selectedPaymentMethod?.payment_method}
                                                        onChange={(value) => {
                                                            const selectedOption = listOfPaymentMethods.find((s) => s.payment_method === value);
                                                            setSelectedPaymentMethod(selectedOption);
                                                        }}
                                                    >
                                                        {listOfPaymentMethods.map((s) => (
                                                            <Select.Option key={s.payment_method} value={s.payment_method}>
                                                                {s.display_name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        )}
                                    </>
                                )}
                                {currentStep === 2 && (
                                    <>
                                        {!isIban ? (
                                            <div className={styles.formItem}>
                                                <p className={styles.label}>{t('transferPage.labels.cardNumber')}</p>
                                                <Form.Item
                                                    name="recipient_card"
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (cardNumber.replace(/\s/g, '').length < 16) {
                                                                    return Promise.resolve();
                                                                }
                                                                return validCard({ card: cardNumber.replace(/\s/g, '') })
                                                                    .then((res) => {
                                                                        return Promise.resolve();
                                                                    })
                                                                    .catch((err) => {
                                                                        return Promise.reject('Invalid card number');
                                                                    });
                                                            },
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input value={cardNumber} onChange={handleCardChange} type="tel" autoFocus />
                                                </Form.Item>
                                            </div>
                                        ) : (
                                            <div className={styles.formItem}>
                                                <p className={styles.label}>{t('transferPage.labels.ibanNumber')}</p>
                                                <Form.Item
                                                    name="iban"
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (value.replace(/\s/g, '').length < 10) {
                                                                    return Promise.resolve();
                                                                }
                                                                return validIban({ iban: value })
                                                                    .then((res) => {
                                                                        return Promise.resolve();
                                                                    })
                                                                    .catch((err) => {
                                                                        return Promise.reject('Invalid iban number');
                                                                    });
                                                            },
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input value={iban} onChange={(e) => setIban(e.target.value)} autoFocus />
                                                </Form.Item>
                                            </div>
                                        )}
                                        <div className="d-flex align-items-center mb-3">
                                            <Switch checked={isIban} onChange={() => setIsIban(!isIban)} />
                                            <p className={classNames(styles.label, 'mt-2 ms-3')}>{t('transferPage.labels.paymentMethod')}</p>
                                        </div>
                                        <div className={styles.formItem}>
                                            <p className={styles.label}>{t('transferPage.labels.phone')}</p>
                                            <Form.Item rules={[{ required: true, message: t('rules.requiredField') }]} name="recipient_phone">
                                                <PhoneInput country="kz" onChange={(e) => handleChangePhoneNumber(e as string)} />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.checkboxWrapper}>
                                            <Checkbox value={agreement} checked={agreement} onChange={handleAgree}></Checkbox>
                                            <p className={styles.checkboxText}>
                                                <Trans
                                                    i18nKey="transferPage.orderPolicy.description"
                                                    values={{
                                                        link: t('transferPage.orderPolicy.link'),
                                                    }}
                                                    components={{
                                                        1: <a target="_blank" rel="noopener noreferrer" href={orderLink} className={styles.link} />,
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    </>
                                )}

                                <Button disabled={currentStep === 2 && !agreement} type="primary" htmlType="submit" className={styles['send-button']}>
                                    {currentStep === 1 ? t('transferPage.next') : t('transferPage.transfer')}
                                </Button>
                            </Form>
                        </>
                    )}
                    {/* {currentStep === 3 && (
                    <>
                        <MercuryoWidget />
                    </>
                )} */}
                    {currentStep === 3 && (
                        <>
                            <TimerIcon className="mb-3" />
                            <p className={styles.finishTitle}>{t('transferPage.processing', { number: idClient })}</p>
                            <p className={styles.subtitle}>
                                {t('transferPage.sendAmount', { receiveAmount: receiveAmount, receiveCurrency: receiveCurrency?.char_code })}
                                <br />
                                {t('transferPage.requisites', {
                                    cardNumber: initialValues?.recipient_card ? maskCardNumber(initialValues.recipient_card) : initialValues.iban,
                                })}
                            </p>
                            <Button onClick={handleNavigateHome} type="primary" className={styles['return-button']}>
                                {t('transferPage.backToHome')}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
