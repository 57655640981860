import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styles from './TermsPage.module.scss';

export const TermsPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    function renderListItems() {
        const items = t('termsPage.terms', { returnObjects: true });

        if (Array.isArray(items)) {
            return items.map((item, index) => (
                <div key={index}>
                    <p className={styles.subtitle}>{item.title}</p>
                    <p className={styles.description}>
                        {item.content.split('\n').map((line: string, lineIndex: string) => (
                            <React.Fragment key={lineIndex}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                </div>
            ));
        }

        return null;
    }

    return (
        <>
            <Helmet>
                <title>Q-Send Terms</title>
                <meta content="." name="description" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>
            <div className={styles.wrapper}>
                <Row gutter={100}>
                    <Col lg={9} sm={24}>
                        <h1 className={styles.title}>{t('termsPage.title')}</h1>
                    </Col>
                    <Col lg={15} sm={24}>
                        {renderListItems()}
                    </Col>
                </Row>
            </div>
        </>
    );
};
