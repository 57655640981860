import { selectAuthUser, setAuthUser, setToken } from '@sliceUser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from 'src/pages/Anonymous/LoginPage/LoginPage';
import { RegisterPage } from 'src/pages/Anonymous/RegisterPage/RegisterPage';
import { AnonymousLayout } from 'src/pages/Anonymous/_layout/AnonymousLayout';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';

import { getCurrentUser } from '@api/userServiceAPI';
import { AmlPage } from 'src/pages/AmlPage/AmlPage';
import { ChangePassword } from 'src/pages/Anonymous/ChangePassword/ChangePassword';
import { FaqPage } from 'src/pages/FAQPage/FAQPage';
import { HomePage } from 'src/pages/HomePage/HomePage';

import { AboutCompany } from 'src/pages/AboutCompany/AboutCompany';
import { Career } from 'src/pages/Career/Career';
import { Contacts } from 'src/pages/Contacts/Contacts';
import { HomePageCountries } from 'src/pages/HomePageCountries/HomePage';
import { LimitsPage } from 'src/pages/LimitsPage/LimitsPage';
import { News } from 'src/pages/News/News';
import { NewsPage } from 'src/pages/NewsPage/NewsPage';
import { OurCulture } from 'src/pages/OurCulture/OurCulture';
import { Partnership } from 'src/pages/Partnership/Partnership';
import { PrivacyPage } from 'src/pages/PrivacyPage/PrivacyPage';
import { ReferralProgram } from 'src/pages/ReferralProgram/ReferralProgram';
import { ReviewPage } from 'src/pages/ReviewPage/ReviewPage';
import { Safety } from 'src/pages/Safety/Safety';
import { TermsPage } from 'src/pages/TermsPage/TermsPage';
import { TransferPage } from 'src/pages/User/TransferPage/TransferPage';
import { UserProfilePage } from 'src/pages/User/UserProfilePage/UserProfilePage';
import { UserLayout } from 'src/pages/User/_layout/UserLayout';
import { Vacancies } from 'src/pages/Vacancies/Vacancies';
import { Vacancy } from 'src/pages/Vacancy/Vacancy';
import { UserTokenLS } from 'src/services';
import styles from './MainPage.module.scss';

export const MainPage = () => {
    const dispatch = useDispatch();

    const [initialLoading, setInitialLoading] = useState(true);
    const authUser = useSelector(selectAuthUser);

    useEffect(() => {
        const fetchData = async () => {
            const tokenLS = UserTokenLS.getUserToken();
            if (tokenLS) {
                dispatch(setToken(tokenLS));
                try {
                    const res = await getCurrentUser({
                        headers: {
                            Authorization: tokenLS,
                        },
                    });
                    const authResponse = res.data;
                    dispatch(setAuthUser(authResponse));
                } catch (error) {
                    // Handle error if needed
                }
            } else {
                const params = new URLSearchParams(window.location.search);
                const tokenParams = params.get('token');

                if (tokenParams) {
                    dispatch(setToken(tokenParams));
                    UserTokenLS.setUserToken(tokenParams);
                    try {
                        const res = await getCurrentUser({
                            headers: {
                                Authorization: tokenParams,
                            },
                        });
                        const authResponse = res.data;
                        dispatch(setAuthUser(authResponse));
                    } catch (error) {
                        // Handle error if needed
                    }
                }
            }
            setInitialLoading(false);
        };

        fetchData();
    }, [dispatch]);

    return (
        <>
            {!initialLoading && (
                <main className={styles.wrapper}>
                    <Routes>
                        <Route
                            path="/"
                            element={<ProtectedRoute isAllowed={authUser ? true : false}>{authUser ? <UserLayout /> : null}</ProtectedRoute>}
                        >
                            {authUser ? (
                                <>
                                    {/* User's pages */}
                                    <Route index path="/" element={<HomePage />} />
                                    <Route index path="/send-money" element={<HomePageCountries />} />

                                    <Route path="profile" element={<UserProfilePage />} />
                                    <Route path="transfer" element={<TransferPage />} />
                                </>
                            ) : null}
                        </Route>

                        {/* Anonymous pages */}
                        <Route element={<AnonymousLayout />}>
                            <Route index path="/" element={<HomePage />} />
                            <Route index path="/send-money" element={<HomePageCountries />} />
                            <Route index path="/our-culture" element={<OurCulture />} />
                            <Route index path="/our-company" element={<AboutCompany />} />
                            <Route index path="/career" element={<Career />} />
                            <Route index path="/safety" element={<Safety />} />
                            <Route index path="/partnership" element={<Partnership />} />
                            <Route index path="/contacts" element={<Contacts />} />
                            <Route index path="/referral" element={<ReferralProgram />} />
                            <Route index path="/news" element={<News />} />
                            <Route index path="/news/:name" element={<NewsPage />} />
                            <Route index path="/reviews" element={<ReviewPage />} />
                            <Route index path="/vacancies" element={<Vacancies />} />
                            <Route index path="/vacancy/:id" element={<Vacancy />} />
                            <Route path="aml" element={<AmlPage />} />
                            <Route path="faq" element={<FaqPage />} />
                            <Route path="privacy" element={<PrivacyPage />} />
                            <Route path="terms" element={<TermsPage />} />
                            <Route path="limits" element={<LimitsPage />} />
                            <Route index path="login" element={<LoginPage />} />
                            <Route path="register" element={<RegisterPage />} />
                            <Route path="change-password" element={<ChangePassword />} />
                        </Route>

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </main>
            )}
        </>
    );
};
