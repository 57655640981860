import { configureStore } from '@reduxjs/toolkit';
import { coreReducer } from './core';
import { transferReducer } from './transfer/slice';
import { userListenerMiddleware } from './user/effects';
import { userReducer } from './user/slice';

export function makeStore() {
    return configureStore({
        reducer: {
            coreReducer,
            userReducer,
            transferReducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(userListenerMiddleware.middleware),
    });
}

const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;

export default store;
