import { PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IProtectedRouteProps {
    isAllowed: boolean | null;
    redirectTo?: string;
}

export const ProtectedRoute = ({ isAllowed: allow, redirectTo: redirectUrl = '/login', children }: PropsWithChildren<IProtectedRouteProps>) => {
    // Still checking authentication status, don't redirect yet
    if (allow === null) {
        return null;
    }

    // Not allowed, redirect to login
    if (!allow) {
        return <Navigate to={redirectUrl} replace />;
    }

    // Allowed, render children or Outlet
    return children ? <>{children}</> : <Outlet />;
};
