import i18n from '@utils/i18n';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLanguage, setCurrentLanguage } from './store/core/slice';

import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { GlobalLoader } from './components/GlobalLoader/GlobalLoader';
import { HttpInterceptor } from './components/HttpInterceptor/HttpInterceptor';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { MainPage } from './pages/_general/MainPage/MainPage';
import { UserTokenLS } from './services';
import { LocalLS } from './services/local-storage/LocaleLS';
import { selectTokenUser, setToken } from './store/user/slice';
dayjs.extend(relativeTime);
dayjs.extend(utc);

// dayjs locales. Add more if necessary
import('dayjs/locale/en');
import('dayjs/locale/ru');

export const App = () => {
    const dispatch = useDispatch();

    const currentLanguage = useSelector(selectCurrentLanguage);
    const token = useSelector(selectTokenUser);

    const [tokenState, setTokenState] = useState<string>('');

    useEffect(() => {
        const tokenLS = UserTokenLS.getUserToken();
        const params = new URLSearchParams(window.location.search);
        const tokenParams = params.get('token');
        if (tokenLS) {
            dispatch(setToken(tokenLS));
            setTokenState(tokenLS);
        }
        if (tokenParams) {
            dispatch(setToken(tokenParams));
            setTokenState(tokenParams);
            UserTokenLS.setUserToken(tokenParams);
        }
    }, [token]);

    useEffect(() => {
        const localeLS = LocalLS.getLocale();
        if (localeLS) {
            dispatch(setCurrentLanguage(localeLS));
            i18n.changeLanguage(localeLS);
            dayjs.locale(localeLS);
        } else {
            i18n.changeLanguage(currentLanguage);
            dayjs.locale(currentLanguage);
        }
    }, [currentLanguage]);

    return (
        <>
            <ScrollToTop />
            <GlobalLoader />
            <HttpInterceptor token={token || tokenState} />

            <MainPage />
        </>
    );
};
