import { ChevronRight } from '@icons';
import { Col, Row } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './Vacancies.module.scss';

type DescriptionProp = {
    title: string;
    subtitle: string;
    id: string;
};

export const Vacancies = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onVacancyClick = (id: string) => {
        navigate('/vacancy/' + id);
    };

    function renderListItems() {
        const items = t('vacanciesPage.listOfVacancies', { returnObjects: true });
        if (Array.isArray(items)) {
            return (
                <Row gutter={[20, 20]}>
                    {items.map((item: DescriptionProp) => (
                        <Col lg={12} key={item.id}>
                            <div onClick={() => onVacancyClick(item.id)} className={styles.description}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className={styles['description-title']}>{item.title}</p>
                                        <p className={styles['description-subtitle']}>{item.subtitle}</p>
                                    </div>
                                    <ChevronRight />
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            );
        }

        return null;
    }

    const script = document.createElement('script');
    script.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TMBCB888');
`;
    document.head.appendChild(script);

    return (
        <>
            <Helmet>
                <title>{t('metaTags.vacanciesPage.title')}</title>
                <meta name="description" content={t('metaTags.vacanciesPage.description')} />
                <meta name="keywords" content={t('metaTags.vacanciesPage.keywords')} />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>

            <div className={styles.wrapper}>
                <h1 className={styles.title}>{t('vacanciesPage.title')}</h1>

                {renderListItems()}
            </div>
        </>
    );
};
