export class UserTokenLS {
    private static readonly key = 'token';

    public static getUserToken() {
        const userToken = localStorage.getItem(this.key);
        if (userToken) {
            return JSON.parse(userToken) as string;
        }
    }

    public static setUserToken(tokenStr: string) {
        localStorage.setItem(this.key, JSON.stringify(tokenStr));
    }

    public static clear() {
        localStorage.removeItem(this.key);
    }
}
