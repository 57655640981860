import { Button, Checkbox, Col, Form, Input, Progress, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { useFormValidation } from 'src/hooks/form-validation.hook';

import { getCurrentUser, loginByGoogle, register } from '@api/userServiceAPI';
import { EyeClosed, EyeOpen } from '@icons';
import { IUserProps, setAuthUser, setToken } from '@sliceUser';
import { dateForServer } from '@utils/utils';
import { Helmet } from 'react-helmet';
import { UserTokenLS } from 'src/services';
import styles from './RegisterPage.module.scss';

export type IInitialValueProps = {
    birthDay?: number;
    birthMonth?: number;
    birthYear?: number;
    dateIssueDay?: number;
    dateIssueMonth?: number;
    dateIssueYear?: number;
    dateExpireDay?: number;
    dateExpireMonth?: number;
    dateExpireYear?: number;
    user?: IUserProps;
    agreement?: boolean;
    authority?: string;
    country?: string;
    passport_number?: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    phone_number?: string;
};

export const RegisterPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const listOfCountries = useMemo(() => countryList().getData(), []);

    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState<IInitialValueProps>({ agreement: true });
    const [userData, setUserData] = useState<IUserProps>();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [birthDay, setBirthDay] = useState<string>('');
    const [birthMonth, setBirthMonth] = useState<string>('');
    const [birthYear, setBirthYear] = useState<string>('');
    const [dateIssueDay, setDateIssueDay] = useState<string>('');
    const [dateIssueMonth, setDateIssueMonth] = useState<string>('');
    const [dateIssueYear, setDateIssueYear] = useState<string>('');
    const [dateExpireDay, setDateExpireDay] = useState<string>('');
    const [dateExpireMonth, setDateExpireMonth] = useState<string>('');
    const [dateExpireYear, setDateExpireYear] = useState<string>('');
    const [isMailConfirmed, setIsMailConfirmed] = useState<boolean>(false);
    const [isEmailStep, setIsEmailStep] = useState<boolean>(false);
    const [selectedCitizenship, setSelectedCitizenship] = useState<
        | {
              label: string;
              value: string;
          }
        | undefined
    >(listOfCountries.find((c) => c.value === 'KZ'));
    const { validateForm } = useFormValidation(form);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        form.setFieldValue('agreement', true);
        if (token) {
            dispatch(setToken(token));
            UserTokenLS.setUserToken(token);

            getCurrentUser({
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                const authResponse = res.data;
                setIsMailConfirmed(authResponse.is_email_confirmed!);
                setUserData(authResponse);
                if (authResponse.step !== 3) {
                    setCurrentStep(authResponse.step! + 1);
                } else {
                    setCurrentStep(authResponse.step!);
                }
                if (initialValues) {
                    setInitialValues({ ...initialValues, agreement: true, user: { ...authResponse, agreement: true } });
                } else {
                    setInitialValues({ user: { ...authResponse, agreement: true } });
                }
            });
        }
        if (selectedCitizenship) {
            form.setFieldValue('country', selectedCitizenship.value);
        }
    }, []);

    const submit = (formValue: IInitialValueProps) => {
        if (initialValues && formValue) {
            register({
                email: userData?.email,
                birthDate: `${formValue.birthYear!}-${formValue.birthMonth!}-${formValue.birthDay}`,
                country: formValue.country,
                passport_info: {
                    passport_number: formValue.passport_number,
                    authority: formValue.authority,
                    date_expiry: dateForServer(new Date(formValue.dateExpireYear!, formValue.dateExpireMonth! - 1, formValue.dateExpireDay)),
                    date_issue: dateForServer(new Date(formValue.dateIssueYear!, formValue.dateIssueMonth! - 1, formValue.dateIssueDay)),
                },
                agreement: formValue.agreement,
            }).then((res) => {
                dispatch(setToken(res.data.token));
                UserTokenLS.setUserToken(res.data.token);

                getCurrentUser({
                    headers: {
                        Authorization: res.data.token,
                    },
                }).then((res) => {
                    dispatch(setAuthUser(res.data));
                });
                navigate('/');
            });
        }
    };

    const validate = () => {
        const fieldsToCheck = [
            'email',
            'first_name',
            'last_name',
            'phone_number',
            'password',
            'repeatPassword',
            'birthDate',
            'passport_number',
            'authority',
            'birthDay',
            'birthMonth',
            'birthYear',
            'dateIssueDay',
            'dateIssueMonth',
            'dateIssueYear',
            'dateExpireDay',
            'dateExpireMonth',
            'dateExpireYear',
            'agreement',
            'country',
        ];

        validateForm(fieldsToCheck, () => {
            const additionalFieldsToCheck = ['agreement', 'acceptPersonalDataProcessing', 'acceptPrivacyPolicy'];
            return Object.values(form.getFieldsValue(additionalFieldsToCheck)).includes(false);
        });
    };

    const registerByGoogle = () => {
        loginByGoogle().then((res) => {
            window.location.href = res.data;
            setIsEmailStep(true);
        });
    };

    const changeStep = (formValue: IInitialValueProps) => {
        if (currentStep === 1) {
            register({
                email: form.getFieldValue('email'),
                password: form.getFieldValue('password'),
            }).then((res) => {
                dispatch(setToken(res.data.token));
                UserTokenLS.setUserToken(res.data.token);

                setIsEmailStep(true);
            });
        } else {
            register({
                email: userData?.email,
                first_name: formValue.first_name,
                last_name: formValue.last_name,
                middle_name: formValue?.middle_name,
                phone_number: userData?.phone_number,
                agreement: true,
            }).then((res) => {
                dispatch(setToken(res.data.token));
                UserTokenLS.setUserToken(res.data.token);

                setCurrentStep(currentStep + 1);
            });
            setInitialValues({ ...initialValues, user: { ...initialValues!.user, ...formValue, agreement: true }, ...formValue, agreement: true });
        }
    };

    const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        if (+value >= 1 && +value <= 31) {
            switch (name) {
                case 'birth':
                    return setBirthDay(value);
                case 'date_issue':
                    return setDateIssueDay(value);
                case 'date_expiry':
                    return setDateExpireDay(value);
                default:
                    return setBirthDay(value);
            }
        }
    };

    const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        if (+value >= 1 && +value <= 12) {
            switch (name) {
                case 'birth':
                    return setBirthMonth(value);
                case 'date_issue':
                    return setDateIssueMonth(value);
                case 'date_expiry':
                    return setDateExpireMonth(value);
                default:
                    return setBirthMonth(value);
            }
        }
    };

    const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        const currentYear = dayjs().year();
        switch (name) {
            case 'birth':
                if (+value <= currentYear) {
                    return setBirthYear(value);
                }
                return;
            case 'date_issue':
                if (+value <= currentYear) {
                    return setDateIssueYear(value);
                }
                return;
            case 'date_expiry':
                if (+value >= currentYear) {
                    return setDateExpireYear(value);
                }
                return;
            default:
                return setBirthYear(value);
        }
    };

    const handleChangePhoneNumber = (e: string) => {
        form.setFieldValue('phone_number', e);
        setInitialValues({ ...initialValues, user: { ...initialValues!.user, phone_number: e } });
        setUserData({ ...userData, phone_number: e });
    };

    return (
        <>
            <Helmet>
                <title>Register on Q-Send</title>
                <meta content="." name="description" />
            </Helmet>
            <div className={styles.wrapper}>
                <div>
                    <Form
                        form={form}
                        name="register"
                        wrapperCol={{ span: 24 }}
                        initialValues={initialValues}
                        autoComplete="off"
                        onFinish={currentStep === 3 ? submit : changeStep}
                        className={styles.form}
                        onChange={validate}
                    >
                        {!isEmailStep && (
                            <>
                                <h1 className={styles.title}>{t('registerPage.createTitle')}</h1>
                                <p className={styles.stepLabel}>{t('registerPage.steps', { step: currentStep })}</p>
                                <Progress className={styles.progress} showInfo={false} percent={currentStep * (100 / 3)} steps={3} />
                            </>
                        )}

                        {!isEmailStep && currentStep === 1 && (
                            <>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.email')}</p>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { type: 'email', message: t('rules.wrongEmail') },
                                            { required: true, message: t('rules.requiredField') },
                                        ]}
                                    >
                                        <Input autoFocus />
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.password')}</p>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                pattern: /^(?=.*[A-Z])(?=.*[!@#$%^&*/])[A-Za-z\d!@#$%^&*/]{8,}$/,
                                                message: t('rules.weakPassword'),
                                            },
                                            { required: true, message: t('rules.requiredField') },
                                        ]}
                                    >
                                        <Input.Password
                                            iconRender={(isOpen) =>
                                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div className="w-100">
                                    <p className={styles.passwordRule}>{t('registerPage.labels.passwordRule')}</p>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.repeatPassword')}</p>
                                    <Form.Item
                                        name="repeatPassword"
                                        rules={[
                                            { required: true, message: t('rules.requiredField') },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t('rules.notMatched')));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            iconRender={(isOpen) =>
                                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </>
                        )}
                        {isEmailStep && (
                            <>
                                <div className={styles.formItem}>
                                    <p className={styles.title}>{t('registerPage.confirmTitle')}</p>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.emailLabel}>{t('registerPage.confirmDescription')}</p>
                                    <p className={styles.emailLabel}>{t('registerPage.confirmCheckEmailDesc')}</p>
                                </div>
                            </>
                        )}
                        {isMailConfirmed && currentStep === 2 && (
                            <>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.name')}</p>
                                    <Form.Item
                                        name="first_name"
                                        rules={[
                                            {
                                                pattern: /^[a-zA-Z]+$/,
                                                message: t('rules.fillInLatin'),
                                            },
                                            { required: true, message: t('rules.requiredField') },
                                        ]}
                                    >
                                        <Input autoFocus />
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.lastName')}</p>
                                    <Form.Item
                                        name="last_name"
                                        rules={[
                                            {
                                                pattern: /^[a-zA-Z]+$/,
                                                message: t('rules.fillInLatin'),
                                            },
                                            { required: true, message: t('rules.requiredField') },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.middleName')}</p>
                                    <Form.Item
                                        name="middle_name"
                                        rules={[
                                            {
                                                pattern: /^[a-zA-Z]+$/,
                                                message: t('rules.fillInLatin'),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.phone')}</p>
                                    <PhoneInput value={userData?.phone_number} country="kz" onChange={(e) => handleChangePhoneNumber(e as string)} />
                                </div>
                            </>
                        )}
                        {isMailConfirmed && currentStep === 3 && (
                            <>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.birthday')}</p>
                                    <Row gutter={5}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="birthDay"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1 && value <= 31 ? Promise.resolve() : Promise.reject(t('rules.carefully')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="birth"
                                                    placeholder={t('registerPage.day')}
                                                    onChange={handleDayChange}
                                                    value={birthDay}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="birthMonth"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1 && value <= 12 ? Promise.resolve() : Promise.reject(t('rules.monthRange')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="birth"
                                                    placeholder={t('registerPage.month')}
                                                    onChange={handleMonthChange}
                                                    value={birthMonth}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="birthYear"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1900 && value <= dayjs().year()
                                                                ? Promise.resolve()
                                                                : Promise.reject(t('rules.carefully')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="birth"
                                                    placeholder={t('registerPage.year')}
                                                    onChange={handleYearChange}
                                                    value={birthYear}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.citizenship')}</p>
                                    <Form.Item rules={[{ required: true, message: t('rules.requiredField') }]} name="country">
                                        <Select
                                            value={selectedCitizenship?.label}
                                            onChange={(value) => {
                                                const selectedOption = listOfCountries.find((s) => s.label === value);
                                                setSelectedCitizenship(selectedOption);
                                            }}
                                        >
                                            {listOfCountries.map((s) => (
                                                <Select.Option key={s.value} value={s.value}>
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.passportData')}</p>
                                    <Form.Item name="passport_number" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.authority')}</p>
                                    <Form.Item rules={[{ required: true, message: t('rules.requiredField') }]} name="authority">
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.dateIssue')}</p>
                                    <Row gutter={5}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="dateIssueDay"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1 && value <= 31 ? Promise.resolve() : Promise.reject(t('rules.carefully')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="date_issue"
                                                    placeholder={t('registerPage.day')}
                                                    onChange={handleDayChange}
                                                    value={dateIssueDay}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="dateIssueMonth"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1 && value <= 12 ? Promise.resolve() : Promise.reject(t('rules.monthRange')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="date_issue"
                                                    placeholder={t('registerPage.month')}
                                                    onChange={handleMonthChange}
                                                    value={dateIssueMonth}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="dateIssueYear"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1900 && value <= dayjs().year()
                                                                ? Promise.resolve()
                                                                : Promise.reject(t('rules.carefully')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="date_issue"
                                                    placeholder={t('registerPage.year')}
                                                    onChange={handleYearChange}
                                                    value={dateIssueYear}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.dateExpire')}</p>
                                    <Row gutter={5}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="dateExpireDay"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1 && value <= 31 ? Promise.resolve() : Promise.reject(t('rules.carefully')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="date_expiry"
                                                    placeholder={t('registerPage.day')}
                                                    onChange={handleDayChange}
                                                    value={dateExpireDay}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="dateExpireMonth"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= 1 && value <= 12 ? Promise.resolve() : Promise.reject(t('rules.monthRange')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="date_expiry"
                                                    placeholder={t('registerPage.month')}
                                                    onChange={handleMonthChange}
                                                    value={dateExpireMonth}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="dateExpireYear"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value >= dayjs().year() ? Promise.resolve() : Promise.reject(t('registerPage.IDExpired')),
                                                    },
                                                    { required: true, message: t('rules.requiredField') },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    name="date_expiry"
                                                    placeholder={t('registerPage.year')}
                                                    onChange={handleYearChange}
                                                    value={dateExpireYear}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <div className={styles.checkboxWrapper}>
                                    <Form.Item name="agreement" className="me-3" valuePropName="checked">
                                        <Checkbox></Checkbox>
                                    </Form.Item>
                                    <p className={styles.checkboxText}>
                                        <Trans
                                            i18nKey="registerPage.registerPolicy.description"
                                            values={{
                                                link: t('registerPage.registerPolicy.link'),
                                            }}
                                            components={{
                                                1: <a href="/privacy" target="_blank" rel="noopener noreferrer" className={styles.link} />,
                                            }}
                                        />
                                    </p>
                                </div> */}
                            </>
                        )}
                        {!isEmailStep && currentStep === 1 && (
                            <div className={styles.checkboxWrapper}>
                                <Form.Item name="agreement" className="me-3" valuePropName="checked">
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <p className={styles.checkboxText}>
                                    <Trans
                                        i18nKey="registerPage.registerPolicy.description"
                                        values={{
                                            link: t('registerPage.registerPolicy.link'),
                                        }}
                                        components={{
                                            1: <NavLink to="/terms" target="_blank" rel="noopener noreferrer" className={styles.link} />,
                                        }}
                                    />
                                </p>
                            </div>
                        )}
                        {currentStep === 3 && (
                            <span className="text-center">
                                <p className={styles.checkboxText}>{t('registerPage.mercuryoAgreement')}</p>{' '}
                                <a className={styles.link} href="https://mercuryo.io/legal/terms/" target="blank">
                                    {t('registerPage.mercuryoTermsOfService')}
                                </a>
                                ,{' '}
                                <a className={styles.link} href="https://mercuryo.io/legal/privacy/" target="blank">
                                    {t('registerPage.mercuryoPrivacy')}
                                </a>
                                ,{' '}
                                <a className={styles.link} href="https://mercuryo.io/legal/cookies/" target="blank">
                                    {t('registerPage.mercuryoCookies')}
                                </a>
                            </span>
                        )}

                        {((!isEmailStep && currentStep === 1) || isMailConfirmed) && (
                            <Button disabled={!initialValues?.agreement} className={styles.button} type="primary" htmlType="submit">
                                {t('registerPage.next')}
                            </Button>
                        )}

                        {!isEmailStep && currentStep === 1 && (
                            <Button className={styles.buttonGoogle} type="ghost" onClick={registerByGoogle}>
                                {t('registerPage.registerByGoogle')}
                            </Button>
                        )}
                        {!isEmailStep && (
                            <>
                                <div className={styles.bottomTxt}>{t('registerPage.registered')}</div>
                                <Link to="/login">{t('registerPage.enterAccount')}</Link>
                            </>
                        )}
                    </Form>
                </div>
            </div>
        </>
    );
};
