import { useTranslation } from 'react-i18next';
import styles from './NewsBlock.module.scss';

export type NewsProp = {
    title: string;
    subtitle: string;
    types: string[];
    keys: string[];
    img: string;
    url: string;
};

type NewsBlockProps = {
    news: NewsProp;
    onClick: () => void;
};

export const NewsBlock = ({ news, onClick }: NewsBlockProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.block} onClick={onClick}>
            <img className={styles.img} src={`/img/news_images/${news.img}.png`} alt={news.img} />
            <div className="d-flex">
                {news.types
                    .filter((type) => type !== t('newsPage.types.allNews'))
                    .map((type) => (
                        <div key={type} className={styles.type}>
                            {type}
                        </div>
                    ))}
            </div>
            <p className={styles.title}>{news.title}</p>
            <p className={styles.subtitle}>{news.subtitle}</p>
        </div>
    );
};
