/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { IUserProps, IUserState, initialUserState } from './state';

// Slice
export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        signUp: (state: IUserState, action: PayloadAction<IUserProps>) => {},
        login: (state: IUserState, action: PayloadAction<{ email: string; password: string }>) => {},
        logoutLocal: (state: IUserState, action: PayloadAction) => {},
        getAuthUser: (state: IUserState, action: PayloadAction) => {},
        setAuthUser: (state: IUserState, action: PayloadAction<IUserProps | null>) => {
            state.authUser = action.payload;
        },
        setToken: (state: IUserState, action: PayloadAction<string | null>) => {
            state.token = action.payload;
        },
    },
});

// Actions
export const { login, signUp, logoutLocal, getAuthUser, setAuthUser, setToken } = userSlice.actions;

// Reducer
export const userReducer = userSlice.reducer;

// Selectors
export const selectAuthUser = (state: RootState) => state.userReducer.authUser;
export const selectTokenUser = (state: RootState) => state.userReducer.token;
