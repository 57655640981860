import { ITransferHistoryProps, getTransferHistory, setTransactionCheck } from '@api/paymentServiceAPI';
import { changeUserData, getCurrentUser } from '@api/userServiceAPI';
import { PendingBigIcon, PendingIcon, RejectIcon, SuccessIcon } from '@icons';
import { IUserProps, logoutLocal, selectAuthUser, selectTokenUser, setAuthUser, setToken } from '@sliceUser';
import { TransferStatusesEnums } from '@utils/constants';
import { dateForServer } from '@utils/utils';
import { Button, Col, Form, Input, Modal, Row, Select, message } from 'antd';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import { IInitialValueProps } from 'src/pages/Anonymous/RegisterPage/RegisterPage';
import { UserTokenLS } from 'src/services';
import styles from './UserProfilePage.module.scss';

export const UserProfilePage = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const listOfCountries = useMemo(() => countryList().getData(), []);

    const authUser = useSelector(selectAuthUser);
    const tokenSelector = useSelector(selectTokenUser);
    const [initialValues, setInitialValues] = useState<IInitialValueProps>();
    const [userData, setUserData] = useState<IUserProps>();

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [birthDay, setBirthDay] = useState<string>('');
    const [birthMonth, setBirthMonth] = useState<string>('');
    const [birthYear, setBirthYear] = useState<string>('');
    const [dateIssueDay, setDateIssueDay] = useState<string>('');
    const [dateIssueMonth, setDateIssueMonth] = useState<string>('');
    const [dateIssueYear, setDateIssueYear] = useState<string>('');
    const [dateExpireDay, setDateExpireDay] = useState<string>('');
    const [dateExpireMonth, setDateExpireMonth] = useState<string>('');
    const [dateExpireYear, setDateExpireYear] = useState<string>('');
    const [selectedCitizenship, setSelectedCitizenship] = useState<
        | {
              label: string;
              value: string;
          }
        | undefined
    >(listOfCountries.find((c) => c.value === 'KZ'));
    const [transferHistory, setTransferHistory] = useState<ITransferHistoryProps[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedCheck, setSelectedCheck] = useState<ITransferHistoryProps>();

    useEffect(() => {
        if (tokenSelector) {
            getCurrentUser().then((res) => {
                setUserData(res.data);
                setInitialValues({
                    ...res.data,
                    birthDay: dayjs(res.data?.birthDate).date(),
                    birthMonth: dayjs(res.data?.birthDate).month() + 1,
                    birthYear: dayjs(res.data?.birthDate).year(),
                    dateIssueDay: dayjs(res.data?.passport_info?.date_issue).date(),
                    dateIssueMonth: dayjs(res.data?.passport_info?.date_issue).month() + 1,
                    dateIssueYear: dayjs(res.data?.passport_info?.date_issue).year(),
                    dateExpireDay: dayjs(res.data?.passport_info?.date_expiry).date(),
                    dateExpireMonth: dayjs(res.data?.passport_info?.date_expiry).month() + 1,
                    dateExpireYear: dayjs(res.data?.passport_info?.date_expiry).year(),
                    passport_number: res.data?.passport_info?.passport_number,
                    authority: res.data?.passport_info?.authority,
                });
                form.setFieldsValue({
                    ...res.data,
                    birthDay: dayjs(res.data?.birthDate).date(),
                    birthMonth: dayjs(res.data?.birthDate).month() + 1,
                    birthYear: dayjs(res.data?.birthDate).year(),
                    dateIssueDay: dayjs(res.data?.passport_info?.date_issue).date(),
                    dateIssueMonth: dayjs(res.data?.passport_info?.date_issue).month() + 1,
                    dateIssueYear: dayjs(res.data?.passport_info?.date_issue).year(),
                    dateExpireDay: dayjs(res.data?.passport_info?.date_expiry).date(),
                    dateExpireMonth: dayjs(res.data?.passport_info?.date_expiry).month() + 1,
                    dateExpireYear: dayjs(res.data?.passport_info?.date_expiry).year(),
                    passport_number: res.data?.passport_info?.passport_number,
                    authority: res.data?.passport_info?.authority,
                });
                setSelectedCitizenship(listOfCountries.find((c) => c.value === res.data?.country));
            });
            getTransferHistory().then((response) => {
                setTransferHistory(response.data);
            });
        } else {
            const params = new URLSearchParams(window.location.search);
            const token = params.get('token');
            const isKyc = params.get('isKyc');
            if (token) {
                if (!isKyc) {
                    message.warning('Contact to Q-Send support team');
                }
                UserTokenLS.setUserToken(token);
                dispatch(setToken(token));

                getCurrentUser({
                    headers: {
                        Authorization: token,
                    },
                }).then((res) => {
                    const authResponse = res.data;
                    getTransferHistory().then((response) => {
                        setTransferHistory(response.data);
                    });
                    setUserData(authResponse);
                    setInitialValues({
                        ...authResponse,
                        birthDay: dayjs(authResponse?.birthDate).date(),
                        birthMonth: dayjs(authResponse?.birthDate).month() + 1,
                        birthYear: dayjs(authResponse?.birthDate).year(),
                        dateIssueDay: dayjs(authResponse?.passport_info?.date_issue).date(),
                        dateIssueMonth: dayjs(authResponse?.passport_info?.date_issue).month() + 1,
                        dateIssueYear: dayjs(authResponse?.passport_info?.date_issue).year(),
                        dateExpireDay: dayjs(authResponse?.passport_info?.date_expiry).date(),
                        dateExpireMonth: dayjs(authResponse?.passport_info?.date_expiry).month() + 1,
                        dateExpireYear: dayjs(authResponse?.passport_info?.date_expiry).year(),
                        passport_number: authResponse?.passport_info?.passport_number,
                        authority: authResponse?.passport_info?.authority,
                    });
                    form.setFieldsValue({
                        ...authResponse,
                        birthDay: dayjs(authResponse?.birthDate).date(),
                        birthMonth: dayjs(authResponse?.birthDate).month() + 1,
                        birthYear: dayjs(authResponse?.birthDate).year(),
                        dateIssueDay: dayjs(authResponse?.passport_info?.date_issue).date(),
                        dateIssueMonth: dayjs(authResponse?.passport_info?.date_issue).month() + 1,
                        dateIssueYear: dayjs(authResponse?.passport_info?.date_issue).year(),
                        dateExpireDay: dayjs(authResponse?.passport_info?.date_expiry).date(),
                        dateExpireMonth: dayjs(authResponse?.passport_info?.date_expiry).month() + 1,
                        dateExpireYear: dayjs(authResponse?.passport_info?.date_expiry).year(),
                        passport_number: authResponse?.passport_info?.passport_number,
                        authority: authResponse?.passport_info?.authority,
                    });
                    setSelectedCitizenship(listOfCountries.find((c) => c.value === authResponse?.country));
                });
            }
        }
    }, []);

    const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        if (+value >= 1 && +value <= 31) {
            switch (name) {
                case 'birth':
                    return setBirthDay(value);
                case 'date_issue':
                    return setDateIssueDay(value);
                case 'date_expiry':
                    return setDateExpireDay(value);
                default:
                    return setBirthDay(value);
            }
        }
    };

    const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        if (+value >= 1 && +value <= 12) {
            switch (name) {
                case 'birth':
                    return setBirthMonth(value);
                case 'date_issue':
                    return setDateIssueMonth(value);
                case 'date_expiry':
                    return setDateExpireMonth(value);
                default:
                    return setBirthMonth(value);
            }
        }
    };

    const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        const currentYear = dayjs().year();
        switch (name) {
            case 'birth':
                if (+value <= currentYear) {
                    return setBirthYear(value);
                }
                return;
            case 'date_issue':
                if (+value <= currentYear) {
                    return setDateIssueYear(value);
                }
                return;
            case 'date_expiry':
                if (+value >= currentYear) {
                    return setDateExpireYear(value);
                }
                return;
            default:
                return setBirthYear(value);
        }
    };

    const handleChangePhoneNumber = (e: string) => {
        form.setFieldValue('phone_number', e);
        setInitialValues({ ...initialValues, phone_number: e });
    };

    const validate = () => {
        const fieldsToCheck = [
            'email',
            'first_name',
            'last_name',
            'phone_number',
            'password',
            'repeatPassword',
            'birthDate',
            'passport_number',
            'authority',
            'birthDay',
            'birthMonth',
            'birthYear',
            'dateIssueDay',
            'dateIssueMonth',
            'dateIssueYear',
            'dateExpireDay',
            'dateExpireMonth',
            'dateExpireYear',
            'agreement',
            'country',
        ];

        validateForm(fieldsToCheck, () => {
            const additionalFieldsToCheck = ['agreement', 'acceptPersonalDataProcessing', 'acceptPrivacyPolicy'];
            return Object.values(form.getFieldsValue(additionalFieldsToCheck)).includes(false);
        });
    };

    const submit = (formValue: IInitialValueProps) => {
        if (initialValues && formValue) {
            changeUserData({
                first_name: formValue.first_name,
                last_name: formValue.last_name,
                middle_name: formValue?.middle_name,
                phone_number: initialValues?.phone_number,
                birthDate: `${formValue.birthYear!}-${formValue.birthMonth!}-${formValue.birthDay}`,
                country: formValue.country,
                passport_info: {
                    passport_number: formValue.passport_number,
                    authority: formValue.authority,
                    date_expiry: dateForServer(new Date(formValue.dateExpireYear!, formValue.dateExpireMonth! - 1, formValue.dateExpireDay)),
                    date_issue: dateForServer(new Date(formValue.dateIssueYear!, formValue.dateIssueMonth! - 1, formValue.dateIssueDay)),
                },
            }).then((res) => {
                setUserData(res.data);
                dispatch(setAuthUser(res.data));
                setInitialValues({
                    ...res.data,
                    birthDay: dayjs(res.data?.birthDate).date(),
                    birthMonth: dayjs(res.data?.birthDate).month() + 1,
                    birthYear: dayjs(res.data?.birthDate).year(),
                    dateIssueDay: dayjs(res.data?.passport_info?.date_issue).date(),
                    dateIssueMonth: dayjs(res.data?.passport_info?.date_issue).month() + 1,
                    dateIssueYear: dayjs(res.data?.passport_info?.date_issue).year(),
                    dateExpireDay: dayjs(res.data?.passport_info?.date_expiry).date(),
                    dateExpireMonth: dayjs(res.data?.passport_info?.date_expiry).month() + 1,
                    dateExpireYear: dayjs(res.data?.passport_info?.date_expiry).year(),
                    passport_number: res.data?.passport_info?.passport_number,
                    authority: res.data?.passport_info?.authority,
                });
                setIsEdit(false);
            });
        }
    };

    const logout = () => {
        dispatch(setToken(''));
        UserTokenLS.clear();

        dispatch(logoutLocal());
        navigate('/');
    };

    const renderStatusIcons = (status: TransferStatusesEnums) => {
        switch (status) {
            case TransferStatusesEnums.CANCELED:
                return <RejectIcon />;
            case TransferStatusesEnums.FAILED:
                return <RejectIcon />;
            case TransferStatusesEnums.REJECTED:
                return <RejectIcon />;
            case TransferStatusesEnums.PAID:
                return <PendingIcon />;
            case TransferStatusesEnums.PROCESSING:
                return <PendingIcon />;
            case TransferStatusesEnums.WITHDRAWAL_COMPLETED:
                return <PendingIcon />;
            case TransferStatusesEnums.FINISHED:
                return <SuccessIcon />;
            default:
                return <PendingIcon />;
        }
    };

    const showOrderModal = (id: number) => {
        setShowModal(true);
        setSelectedCheck(transferHistory.find((el) => el.id_display === id));
    };

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleTransactionCheck = () => {
        setTransactionCheck({ merchant_transaction_id: selectedCheck!.merchant_transaction_id }).then((res) => {
            const link = document.createElement('a');
            link.href = res.data;
            link.download = 'filename.pdf'; // Specify a filename or derive it from the URL
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            link.click();
        });
        setShowModal(false);
    };

    return (
        <>
            <Helmet>
                <title>Q-Send User profile</title>
                <meta content="." name="description" />
            </Helmet>
            <div className={styles.wrapper}>
                {selectedCheck && showModal && (
                    <Modal width={480} closable={false} onCancel={() => setShowModal(false)} closeIcon={null} open={showModal} footer={null}>
                        <div className={styles.checkWrapper}>
                            <Row className={styles.checkHeader} justify="space-between">
                                <Col span={13}>
                                    <div className={styles.checkStatusWrapper}>
                                        {renderStatusIcons(selectedCheck.status as TransferStatusesEnums)}
                                        <div className={styles.checkStatusDis}>
                                            <p className={styles.checkStatus}>{capitalizeFirstLetter(selectedCheck.status_lang)}</p>
                                            <p className={styles.checkDate}>{dayjs(selectedCheck.created_at).format('D MMMM YYYY')}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="text-end" span={8}>
                                    <p className={styles.checkAmount}>
                                        {selectedCheck.recipient_amount} {selectedCheck.recipient_currency}
                                    </p>
                                    <p className={styles.checkSendAmount}>
                                        {selectedCheck.fiat_amount} {selectedCheck.fiat_currency}
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mb-3" justify="space-between">
                                <Col span={10}>
                                    <p className={styles.checkLabel}>{t('userPage.numberOfTransfer')}</p>
                                </Col>
                                <Col className="text-end" span={13}>
                                    <p className={styles.checkValue}>№{selectedCheck.id_display}</p>
                                </Col>
                            </Row>
                            <Row className="mb-3" justify="space-between">
                                <Col span={8}>
                                    <p className={styles.checkLabel}>{t('userPage.paymentMethod')}</p>
                                </Col>
                                <Col className="text-end" span={13}>
                                    <p className={styles.checkValue}>{selectedCheck.payment_method}</p>
                                </Col>
                            </Row>
                            <Row className="mb-3" justify="space-between">
                                <Col span={8}>
                                    <p className={styles.checkLabel}>{t('userPage.requisites')}</p>
                                </Col>
                                <Col className="text-end" span={13}>
                                    {selectedCheck?.recipient_card ? (
                                        <p className={styles.checkValue}>{selectedCheck.recipient_card}</p>
                                    ) : (
                                        <p className={styles.checkValue}>{selectedCheck.iban}</p>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3" justify="space-between">
                                <Col span={8}>
                                    <p className={styles.checkLabel}>{t('userPage.recipient')}</p>
                                </Col>
                                <Col className="text-end" span={13}>
                                    <p className={styles.checkValue}>
                                        {selectedCheck.recipient_name} {selectedCheck.recipient_surname}
                                    </p>
                                </Col>
                            </Row>
                            {/* <Row justify="space-between">
                            <Col span={8}>
                                <p className={styles.checkLabel}>{t('userPage.currency')}</p>
                            </Col>
                            <Col className="text-end" span={8}>
                                <p className={styles.checkValue}>{selectedCheck.recipient_surname}</p>
                            </Col>
                        </Row> */}
                            <Button onClick={handleTransactionCheck} className={styles.checkButton} type="primary">
                                {t('userPage.setOrder')}
                            </Button>
                        </div>
                    </Modal>
                )}
                <Row className={styles.historyRow} style={{ margin: 0 }} gutter={30} justify="space-between">
                    <Col lg={10} md={12} sm={24}>
                        <div className={styles.historyBanner}>
                            {authUser?.is_full_registered && <p className={styles.title}>{t('userPage.historyTitle')}</p>}

                            {authUser?.is_full_registered ? (
                                transferHistory && transferHistory?.length > 0 ? (
                                    <div className={styles.historyData}>
                                        {transferHistory.map((t, i) => (
                                            <Row
                                                onClick={() => showOrderModal(t.id_display)}
                                                align="middle"
                                                gutter={14}
                                                className={styles.historyRow}
                                                key={`history-${i}`}
                                            >
                                                <Col span={4}>{renderStatusIcons(t.status as TransferStatusesEnums)}</Col>
                                                <Col span={14}>
                                                    <div className={styles.cards}>
                                                        <p className={styles.cardNumber}>{t?.recipient_card ? t.recipient_card : t.iban}</p>
                                                        <p className={styles.transferDate}>{dayjs(t.created_at).format('D MMM YYYY')}</p>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <p className={styles.amount}>
                                                        {t.recipient_amount} {t.recipient_currency}
                                                    </p>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                ) : (
                                    <p className={styles.emptyHistory}>{t('userPage.emptyHistory')}</p>
                                )
                            ) : (
                                <div className={styles.historyData}>
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <PendingBigIcon />
                                        <p className={styles.bannerTitle}>{t('userPage.completeFillUserData.title')}</p>
                                        <Button onClick={() => setIsEdit(true)} type="primary">
                                            {t('userPage.completeFillUserData.button')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col lg={9} md={11} sm={24}>
                        {!isEdit ? (
                            <div className={styles.userData}>
                                <p className={styles.name}>
                                    {authUser?.first_name} {authUser?.last_name} {authUser?.middle_name}
                                </p>
                                <div className={styles.infoContainer}>
                                    {authUser?.phone_number && <div className={styles.divider} />}
                                    <p className={styles.infoText}>{authUser?.phone_number}</p>
                                    <div className={styles.divider} />
                                    <p className={styles.infoText}>{authUser?.email}</p>
                                </div>
                                <div className={styles.infoActions}>
                                    <a onClick={() => setIsEdit(true)} className={styles.edit}>
                                        {t('userPage.changeUserData')}
                                    </a>
                                    <Link to="/change-password" className={styles.actionText}>
                                        {t('userPage.changePassword')}
                                    </Link>
                                    <a onClick={logout} className={styles.actionText}>
                                        {t('userPage.logout')}
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.editBanner}>
                                <Form
                                    form={form}
                                    name="user"
                                    wrapperCol={{ span: 24 }}
                                    initialValues={initialValues}
                                    autoComplete="off"
                                    onFinish={submit}
                                    className={styles.form}
                                    onChange={validate}
                                >
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.name')}</p>
                                        <Form.Item
                                            name="first_name"
                                            rules={[
                                                {
                                                    pattern: /^[a-zA-Z]+$/,
                                                    message: t('rules.fillInLatin'),
                                                },
                                                { required: true, message: t('rules.requiredField') },
                                            ]}
                                        >
                                            <Input autoFocus />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.lastName')}</p>
                                        <Form.Item
                                            name="last_name"
                                            rules={[
                                                {
                                                    pattern: /^[a-zA-Z]+$/,
                                                    message: t('rules.fillInLatin'),
                                                },
                                                { required: true, message: t('rules.requiredField') },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.middleName')}</p>
                                        <Form.Item
                                            name="middle_name"
                                            rules={[
                                                {
                                                    pattern: /^[a-zA-Z]+$/,
                                                    message: t('rules.fillInLatin'),
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.phone')}</p>
                                        <PhoneInput
                                            value={userData?.phone_number}
                                            country="kz"
                                            onChange={(e) => handleChangePhoneNumber(e as string)}
                                        />
                                    </div>
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.citizenship')}</p>
                                        <Form.Item rules={[{ required: true, message: t('rules.requiredField') }]} name="country">
                                            <Select
                                                value={selectedCitizenship?.label}
                                                onChange={(value) => {
                                                    const selectedOption = listOfCountries.find((s) => s.label === value);
                                                    setSelectedCitizenship(selectedOption);
                                                }}
                                            >
                                                {listOfCountries.map((s) => (
                                                    <Select.Option key={s.value} value={s.value}>
                                                        {s.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.birthday')}</p>
                                        <Row gutter={5}>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="birthDay"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1 && value <= 31 ? Promise.resolve() : Promise.reject(t('rules.carefully')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        name="birth"
                                                        placeholder={t('registerPage.day')}
                                                        onChange={handleDayChange}
                                                        value={birthDay}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="birthMonth"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1 && value <= 12 ? Promise.resolve() : Promise.reject(t('rules.monthRange')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        name="birth"
                                                        type="number"
                                                        placeholder={t('registerPage.month')}
                                                        onChange={handleMonthChange}
                                                        value={birthMonth}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="birthYear"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1900 && value <= dayjs().year()
                                                                    ? Promise.resolve()
                                                                    : Promise.reject(t('rules.carefully')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        name="birth"
                                                        type="number"
                                                        placeholder={t('registerPage.year')}
                                                        onChange={handleYearChange}
                                                        value={birthYear}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.passportData')}</p>
                                        <Form.Item name="passport_number" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.authority')}</p>
                                        <Form.Item rules={[{ required: true, message: t('rules.requiredField') }]} name="authority">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.dateIssue')}</p>
                                        <Row gutter={5}>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="dateIssueDay"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1 && value <= 31 ? Promise.resolve() : Promise.reject(t('rules.carefully')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        name="date_issue"
                                                        type="number"
                                                        placeholder={t('registerPage.day')}
                                                        onChange={handleDayChange}
                                                        value={dateIssueDay}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="dateIssueMonth"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1 && value <= 12 ? Promise.resolve() : Promise.reject(t('rules.monthRange')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        name="date_issue"
                                                        type="number"
                                                        placeholder={t('registerPage.month')}
                                                        onChange={handleMonthChange}
                                                        value={dateIssueMonth}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="dateIssueYear"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1900 && value <= dayjs().year()
                                                                    ? Promise.resolve()
                                                                    : Promise.reject(t('rules.carefully')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        name="date_issue"
                                                        type="number"
                                                        placeholder={t('registerPage.year')}
                                                        onChange={handleYearChange}
                                                        value={dateIssueYear}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={styles.formItem}>
                                        <p className={styles.label}>{t('registerPage.labels.dateExpire')}</p>
                                        <Row gutter={5}>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="dateExpireDay"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1 && value <= 31 ? Promise.resolve() : Promise.reject(t('rules.carefully')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        name="date_expiry"
                                                        placeholder={t('registerPage.day')}
                                                        onChange={handleDayChange}
                                                        value={dateExpireDay}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="dateExpireMonth"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= 1 && value <= 12 ? Promise.resolve() : Promise.reject(t('rules.monthRange')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        name="date_expiry"
                                                        type="number"
                                                        placeholder={t('registerPage.month')}
                                                        onChange={handleMonthChange}
                                                        value={dateExpireMonth}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="dateExpireYear"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value >= dayjs().year()
                                                                    ? Promise.resolve()
                                                                    : Promise.reject(t('registerPage.IDExpired')),
                                                        },
                                                        { required: true, message: t('rules.requiredField') },
                                                    ]}
                                                >
                                                    <Input
                                                        name="date_expiry"
                                                        type="number"
                                                        placeholder={t('registerPage.year')}
                                                        onChange={handleYearChange}
                                                        value={dateExpireYear}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Button className={styles.button} type="primary" htmlType="submit">
                                        {t('userPage.saveChanges')}
                                    </Button>
                                    <div className={styles.infoActions}>
                                        <a onClick={() => setIsEdit(false)} className={styles.actionText}>
                                            {t('userPage.cancelChanges')}
                                        </a>
                                        <a className={styles.delete}> {t('userPage.deleteAccount')}</a>
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};
