import { BlackCrossIcon, BurgerIcon, ChevronDownIcon, Logo } from '@icons';
import { selectCurrentLanguage, setCurrentLanguage } from '@sliceCore';
import { selectAuthUser } from '@sliceUser';
import { Col, Dropdown, MenuProps, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { LocalLS } from 'src/services/local-storage/LocaleLS';
import styles from './Header.module.scss';

export const Header = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authUser = useSelector(selectAuthUser);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const items: MenuProps['items'] = [
        {
            key: '1',
            onClick: () => {
                dispatch(setCurrentLanguage('ru'));
                LocalLS.setLocale('ru');
            },
            label: <a>RU</a>,
        },
        {
            key: '2',
            onClick: () => {
                dispatch(setCurrentLanguage('en'));
                LocalLS.setLocale('en');
            },
            label: <a>EN</a>,
        },
        {
            key: '3',
            onClick: () => {
                dispatch(setCurrentLanguage('ar'));
                LocalLS.setLocale('ar');
            },
            label: <a>AR</a>,
        },
    ];

    const [isMenuShow, setIsMenuShow] = useState<boolean>(false);

    useEffect(() => {
        const localeLS = LocalLS.getLocale();
        if (localeLS) {
            dispatch(setCurrentLanguage(localeLS));
        }
    }, []);

    return (
        <header className={styles.header}>
            <NavLink to="/">
                <Logo />
            </NavLink>
            <div className={styles.smallNavbar}>
                <Dropdown menu={{ items }} trigger={['click']}>
                    <a className={styles.dropdown}>
                        {currentLanguage?.toUpperCase()}
                        <ChevronDownIcon className="ms-1" />
                    </a>
                </Dropdown>
                <div className={styles.burger} onClick={() => setIsMenuShow(!isMenuShow)}>
                    {!isMenuShow ? <BurgerIcon /> : <BlackCrossIcon />}
                </div>
            </div>
            <Row className={styles.navbar}>
                <Col className="text-end" sm={24} md={18} lg={18}>
                    <NavLink className={styles['action-text']} to={!authUser ? '/login' : '/profile'}>
                        {!authUser
                            ? t('header.login')
                            : authUser.first_name && authUser.last_name
                            ? `${authUser.first_name} ${authUser.last_name}`
                            : t('header.profile')}
                    </NavLink>
                </Col>
                <Col className="text-end" sm={24} md={6} lg={6}>
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <a className={styles.dropdown}>
                            {currentLanguage?.toUpperCase()}
                            <ChevronDownIcon className="ms-1" />
                        </a>
                    </Dropdown>
                </Col>
            </Row>
            {isMenuShow && (
                <div className={styles.burgerMenu}>
                    <NavLink onClick={() => setIsMenuShow(false)} className={styles['action-text']} to={'/'}>
                        {t('header.main')}
                    </NavLink>
                    <NavLink onClick={() => setIsMenuShow(false)} className={styles['action-text']} to={authUser ? '/transfer' : '/login'}>
                        {t('transferPage.title')}
                    </NavLink>
                    <NavLink onClick={() => setIsMenuShow(false)} className={styles['action-text']} to={authUser ? '/profile' : '/login'}>
                        {t('header.profile')}
                    </NavLink>
                </div>
            )}
        </header>
    );
};
