import { InstagramIcon, LinkedInIcon, LogoFooter, TelegramIcon, XIcon } from '@icons';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import styles from './Footer.module.scss';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <footer className={styles.footer}>
                <div className={styles.footerBar}>
                    <div>
                        <NavLink className={styles.logo} to="/">
                            <LogoFooter />
                        </NavLink>
                        <p className={styles.subtitle}>Q-SEND 2023 ©All rights reserved</p>
                    </div>
                    <div className="d-flex flex-column">
                        <Link className={styles.link} to="/">
                            {t('footer.home')}
                        </Link>
                        <Link className={styles.link} to="/our-company">
                            {t('footer.aboutCompany')}
                        </Link>
                        <Link className={styles.link} to="/our-culture">
                            {t('footer.culture')}
                        </Link>
                        <Link className={styles.link} to="/news">
                            {t('footer.news')}
                        </Link>
                        <Link className={styles.link} to="/career">
                            {t('footer.career')}
                        </Link>
                    </div>
                    <div className="d-flex flex-column">
                        <Link className={styles.link} to="/reviews">
                            {t('footer.reviews')}
                        </Link>
                        <Link className={styles.link} to="/safety">
                            {t('footer.safety')}
                        </Link>
                        <Link className={styles.link} to="/referral">
                            {t('footer.promoCodes')}
                        </Link>
                        <Link className={styles.link} to="/partnership">
                            {t('footer.partners')}
                        </Link>
                        <Link className={styles.link} to="/contacts">
                            {t('footer.contacts')}
                        </Link>
                        <Link className={styles.link} to="/referral">
                            {t('footer.referral')}
                        </Link>
                    </div>
                    <div className="d-flex flex-column">
                        <Link className={styles.link} to="/faq">
                            FAQ
                        </Link>
                        <Link className={styles.link} to="/limits">
                            {t('footer.limits')}
                        </Link>
                        <Link className={styles.link} to="/aml">
                            {t('footer.aml')}
                        </Link>
                        <Link className={styles.link} to="/privacy">
                            {t('footer.privacy')}
                        </Link>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <a className={styles.socialLink} target="blank" href="https://instagram.com/qsendcom?igshid=MzRlODBiNWFlZA==">
                                <InstagramIcon />
                            </a>
                            <a className={styles.socialLink} target="blank" href="https://t.me/qsendcom">
                                <TelegramIcon />
                            </a>
                            <a className={styles.socialLink} target="blank" href="https://www.linkedin.com/company/q-send/">
                                <LinkedInIcon />
                            </a>
                            <a className={styles.socialLink} target="blank" href="https://twitter.com/qsendcom">
                                <XIcon />
                            </a>
                        </div>
                        <p className={styles.support}>support@q-send.com</p>
                    </div>
                </div>
            </footer>
        </>
    );
};
