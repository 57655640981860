import { setReview } from '@api/userServiceAPI';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './Vacancy.module.scss';

type DescriptionProp = {
    title: string;
    items: string[];
};

export const Vacancy = () => {
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        const items = t('vacanciesPage.listOfVacancies', { returnObjects: true });
        if (Array.isArray(items)) {
            const vacancy = items.find((item) => item.id === location.pathname.split('/').pop());
            setTitle(vacancy.title);
        }
    }, [i18n.language]);

    const validate = () => {
        validateForm(['email']);
    };

    const submit = (formValue: { name: string; email: string; request: string }) => {
        setReview({
            first_name: formValue.name,
            email: formValue.email,
            type: 'other',
            text: formValue.request,
        });
        setShowModal(false);
    };

    function renderListItems() {
        const items = t('vacanciesPage.listOfVacancies', { returnObjects: true });
        if (Array.isArray(items)) {
            const vacancy = items.find((item) => item.id === location.pathname.split('/').pop());
            return (
                <Row gutter={[20, 20]}>
                    {vacancy.list.map((item: DescriptionProp) => (
                        <Col lg={12} key={item.title}>
                            <p className={styles['description-title']}>{item.title}</p>
                            <ul>
                                {item.items.map((x) => (
                                    <li key={x} className={styles['description-items-list']}>
                                        {x}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    ))}
                </Row>
            );
        }

        return null;
    }

    const script = document.createElement('script');
    script.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TMBCB888');
`;
    document.head.appendChild(script);

    return (
        <>
            <Helmet>
                <title>Q-Send Vacancy</title>
                <meta content="." name="description" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>

            <div className={styles.wrapper}>
                <Modal width={480} closable={false} onCancel={() => setShowModal(false)} closeIcon={null} open={showModal} footer={null}>
                    <div className={styles.modalWrapper}>
                        <p className={styles.modalTitle}>{t('vacanciesPage.modal.title')}</p>
                        <Form
                            form={form}
                            name="login"
                            wrapperCol={{ span: 24 }}
                            initialValues={{ name: '', email: '', request: '' }}
                            autoComplete="off"
                            onFinish={submit}
                            onChange={validate}
                            className="w-100"
                        >
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.name')}</p>
                                <Form.Item name="name" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                    <Input placeholder={t('contactsPage.modal.placeholders.name')} className="w-100" autoFocus />
                                </Form.Item>
                            </div>
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.email')}</p>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { type: 'email', message: t('rules.wrongEmail') },
                                        { required: true, message: t('rules.requiredField') },
                                    ]}
                                >
                                    <Input placeholder={t('contactsPage.modal.placeholders.email')} className="w-100" />
                                </Form.Item>
                            </div>
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('referralPage.modal.textLabel')}</p>
                                <Form.Item name="request" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                    <TextArea placeholder={t('contactsPage.modal.placeholders.request')} rows={4} />
                                </Form.Item>
                            </div>

                            <Button htmlType="submit" className={styles.checkButton} type="primary">
                                {t('contactsPage.modal.button')}
                            </Button>
                        </Form>
                    </div>
                </Modal>
                <Row className={styles['top-content']} gutter={100}>
                    <Col lg={12} sm={24}>
                        {title && <h1 className={styles.title}>{title}</h1>}
                        <Button onClick={() => setShowModal(true)} className={styles.button} type="primary">
                            {t('vacanciesPage.button')}
                        </Button>
                    </Col>
                    <Col lg={12} sm={24}>
                        <p className={styles.subtitle}>{t('vacanciesPage.subtitle')}</p>
                    </Col>
                </Row>
                <div className={styles.divider} />
                {renderListItems()}
            </div>
        </>
    );
};
