import { selectAuthUser } from '@sliceUser';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Footer } from '../../_general/Footer/Footer';

import { Header } from 'src/pages/_general/Header/Header';
import styles from './UserLayout.module.scss';

export const UserLayout = () => {
    const authUser = useSelector(selectAuthUser);
    if (!authUser) {
        return null;
    }

    // useEffect(() => {
    //     setIsCreatePatientPage(location.pathname === '/create-patient');
    // }, [location.pathname]);

    return (
        <div className={styles.wrapper}>
            <div className="sticky-top">
                <Header />
            </div>

            <section className={styles.mainContent}>
                <Outlet />
            </section>

            <Footer />
        </div>
    );
};
