import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import styles from './LimitsPage.module.scss';

type LimitProp = {
    number: string;
    content: string;
    ps?: string;
};

export const LimitsPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    function renderListItems() {
        const items = t('limitsPage.limits', { returnObjects: true });
        if (Array.isArray(items)) {
            const firstGroup = items.slice(0, 3);
            const secondGroup = items.slice(3);

            return (
                <div className={styles.limits}>
                    <Row gutter={29} justify="space-between">
                        {firstGroup.map((item: LimitProp) => (
                            <Col className={styles['limit-content']} lg={8} sm={24} key={item.content}>
                                <p className={styles['limit-number']}>{item.number}</p>
                                <p className={styles['limit-text']}>{item.content}</p>
                                {item.ps && <p className={styles['limit-ps']}>{item.ps}</p>}
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={29} justify="space-between">
                        {secondGroup.map((item: LimitProp) => (
                            <Col className={styles['limit-content']} lg={8} sm={24} key={item.content}>
                                <p className={styles['limit-number']}>{item.number}</p>
                                <p className={styles['limit-text']}>{item.content}</p>
                                {item.ps && <p className={styles['limit-ps']}>{item.ps}</p>}
                            </Col>
                        ))}
                    </Row>
                </div>
            );
        }

        return null;
    }

    return (
        <>
            <Helmet>
                <title>{t('metaTags.limitsPage.title')}</title>
                <meta name="description" content={t('metaTags.limitsPage.description')} />
                <meta name="keywords" content={t('metaTags.limitsPage.keywords')} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>
            <div className={styles.wrapper}>
                <Row className={styles['top-content']} gutter={100}>
                    <Col lg={12} sm={24}>
                        <img className={styles.img} src="/img/limits.png" alt="girl" />
                    </Col>
                    <Col lg={12} sm={24}>
                        <h1 className={styles.title}>{t('limitsPage.title')}</h1>
                        <h2 className={styles.subtitle}>{t('limitsPage.subtitle')}</h2>
                        <div className={styles.partnership}>
                            <img width={98} src={`/img/${t('limitsPage.partnership.img')}.png`} alt={t('limitsPage.partnership.img')} />
                            <p className={styles['partnership-text']}>{t('limitsPage.partnership.text')}</p>
                        </div>
                        <p className={styles.subtitle}>
                            <Trans
                                i18nKey="limitsPage.description"
                                values={{
                                    limit: t('limitsPage.browserLimit'),
                                }}
                                components={{
                                    1: <span className={styles['browser-limit']} />,
                                }}
                            />
                        </p>
                    </Col>
                </Row>
                {renderListItems()}
            </div>
        </>
    );
};
