import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

export type ExchangeCalcDto = {
    commission: number;
    rate: number;
    total: number;
};

export type ExchangeCalcParams = {
    fiat_amount: number;
    fiat_currency: string;
    recipient_currency: string;
    promo_code?: string;
};

export type ICheckLimitsProps = {
    fiat_amount?: number;
    fiat_currency: string;
};

export type ICheckLimitResponse = {
    max: number;
    min: number;
};

export type ITransferHistoryProps = {
    merchant_transaction_id: string;
    id_client: number;
    id_display: number;
    created_at: string;
    fiat_amount: number;
    fiat_currency: string;
    payment_method: string;
    promo_code: string;
    recipient_amount: number;
    recipient_card?: string;
    iban?: string;
    recipient_currency: string;
    recipient_name: string;
    recipient_phone: string;
    recipient_surname: string;
    status: string;
    status_lang: string;
};

export type ITransactionProps = {
    account_type: string;
    fiat_amount: number;
    fiat_currency: string;
    payment_method: string;
    promo_code?: string;
    recipient_amount: number;
    recipient_card?: string;
    iban?: string;
    recipient_currency: string;
    recipient_name: string;
    recipient_phone: string;
    recipient_surname: string;
};

export type ITransactionWithUrlProps = {
    fiat_amount: number;
    fiat_currency: string;
    id_client: number;
    payment_method: string;
    promo_code: string;
    recipient_amount: number;
    recipient_card: string;
    recipient_currency: string;
    recipient_name: string;
    recipient_phone: string;
    recipient_surname: string;
    redirect_url: string;
};

export type TransferCheckProps = {
    fiat_amount: number;
    fiat_currency: string;
    redirect_url: string;
    recipient_currency: string;
};

export type TransferCheckResponse = {
    fiat_amount: number;
    fiat_currency: string;
    redirect_url: string;
    kyc_status: string;
};

export const setExchangeCalc = <TData = AxiosResponse<ExchangeCalcDto>>(
    exchangeCalcDto: ExchangeCalcParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/transaction/calculate-exchange-rate`, exchangeCalcDto, options);
};

export const checkLimits = <TData = AxiosResponse<ICheckLimitResponse>>(
    checkLimitsDto: ICheckLimitsProps,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/transaction/limit/${checkLimitsDto.fiat_currency}`, options);
};

export const getTransferHistory = <TData = AxiosResponse<ITransferHistoryProps[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/transaction/history`, options);
};

export const setTransaction = <TData = AxiosResponse<ITransactionWithUrlProps>>(
    transaction: ITransactionProps,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/transaction/new`, transaction, options);
};

export const validCard = <TData = AxiosResponse<string>>(body: { card: string }, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/transaction/valid/card/${body.card}`, options);
};

export const validIban = <TData = AxiosResponse<string>>(body: { iban: string }, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/transaction/valid/iban/${body.iban}`, options);
};

export const setTransactionCheck = <TData = AxiosResponse<string>>(
    body: { merchant_transaction_id: string },
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/transaction/check/new`, body, options);
};

export const setUserTransactionCheck = <TData = AxiosResponse<TransferCheckResponse>>(
    body: TransferCheckProps,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/transaction/user/check`, body, options);
};
