import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styles from './OurCulture.module.scss';

export const OurCulture = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    function renderRules() {
        const rules = t('ourCulturePage.rules', { returnObjects: true });

        if (Array.isArray(rules)) {
            const firstHalf = rules.slice(0, 3);
            const secondHalf = rules.slice(3, 6);

            return (
                <div>
                    <div className={styles['culture-container']}>
                        <Row gutter={80}>
                            {firstHalf.map((rule, i) => (
                                <Col key={rule.title} lg={8} sm={24}>
                                    <p className={styles.number}>{`0.${i + 1}`}</p>
                                    <p className={styles['rules-title']}>{rule.title}</p>
                                    <p className={styles['rules-subtitle']}>{rule.description}</p>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className={styles['culture-container']}>
                        <Row gutter={80}>
                            {secondHalf.map((rule, i) => (
                                <Col key={rule.title} lg={8} sm={24}>
                                    <p className={styles.number}>{`0.${i + 4}`}</p>
                                    <p className={styles['rules-title']}>{rule.title}</p>
                                    <p className={styles['rules-subtitle']}>{rule.description}</p>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            );
        }

        return null;
    }

    return (
        <div>
            <Helmet>
                <title>{t('metaTags.ourCulturePage.title')}</title>
                <meta name="description" content={t('metaTags.ourCulturePage.description')} />
                <meta name="keywords" content={t('metaTags.ourCulturePage.keywords')} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>
            <div className={styles.wrapper}>
                <Row justify="space-between">
                    <Col lg={10} sm={24}>
                        <h1 className={styles.title}>{t('ourCulturePage.title')}</h1>
                    </Col>
                    <Col lg={10} sm={24}>
                        <h2 className={styles.subtitle}>{t('ourCulturePage.subtitle')}</h2>
                    </Col>
                </Row>
                <img className={styles.img} src="/img/our_culture.png" alt="group" />
                {renderRules()}
                <Col lg={12} sm={24} className={styles.bottomDescription}>
                    <p className={styles.subtitle}>{t('ourCulturePage.bottomDescription')}</p>
                </Col>
            </div>
        </div>
    );
};
