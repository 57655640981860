import { Col, Row, Tabs, TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NewsBlock, NewsProp } from 'src/components/NewsBlock/NewsBlock';
import styles from './News.module.scss';

export type NewsProps = {
    title: string;
    types: string[];
    keys: string[];
    subtitle: string;
    url: string;
    img: string;
    author: string;
    date: string;
    description: {
        title: string;
        img: string;
        paragraphs: string[];
        list: {
            title: string;
            text: string;
        }[];
        conclusion: {
            title: string;
            paragraphs: string[];
        };
        ps: string;
    };
};

export const News = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState<string>('All news');

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    const handleNavigate = (url: string) => {
        navigate(`/news/${slugify(url)}`);
    };

    function slugify(text: string) {
        return text
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
            .replace(/[\s-]+/g, '-'); // Replace spaces and multiple hyphens with a single hyphen
    }

    function renderNewsItems() {
        const items = t('newsPage.content', { returnObjects: true });

        if (Array.isArray(items)) {
            const filteredNews = items.filter((item: NewsProps) => item.keys.includes(selectedType));

            return (
                <div className={styles.limits}>
                    <Row gutter={40}>
                        {filteredNews.map((item: NewsProp) => (
                            <Col className={styles['news-content']} lg={8} sm={24} key={item.title}>
                                <NewsBlock
                                    news={item}
                                    onClick={() => {
                                        handleNavigate(item.url);
                                    }}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            );
        }

        return null;
    }

    const items: TabsProps['items'] = [
        {
            key: 'All news',
            label: t('newsPage.types.allNews'),
            children: <div>{renderNewsItems()}</div>,
        },
        {
            key: 'Q-Send',
            label: t('newsPage.types.qSend'),
            children: renderNewsItems(),
        },
        {
            key: 'Personal finance',
            label: t('newsPage.types.personalFinance'),
            children: renderNewsItems(),
        },
        {
            key: 'Living abroad',
            label: t('newsPage.types.livingAbroad'),
            children: renderNewsItems(),
        },
        {
            key: 'Guide for travelers',
            label: t('newsPage.types.guid'),
            children: renderNewsItems(),
        },
        {
            key: 'World currencies',
            label: t('newsPage.types.currency'),
            children: renderNewsItems(),
        },
    ];

    const onChange = (key: string) => {
        setSelectedType(key);
    };

    return (
        <div>
            <Helmet>
                <title>{t('metaTags.newsPage.description')} </title>
                <meta name="description" content={t('metaTags.newsPage.description')} />
                <meta name="keywords" content={t('metaTags.newsPage.keywords')} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>{t('newsPage.title')}</h1>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
        </div>
    );
};
