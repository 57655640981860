import { setReview } from '@api/userServiceAPI';
import { InstagramIcon, LinkedInIcon, TelegramIcon, XIcon } from '@icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './Contacts.module.scss';

type Icons = 'instagram' | 'telegram' | 'linkedIn' | 'x';

type LinkItem = {
    link: string;
    icon: Icons;
};

const icons: Record<Icons, JSX.Element> = {
    instagram: <InstagramIcon />,
    telegram: <TelegramIcon />,
    linkedIn: <LinkedInIcon />,
    x: <XIcon />,
};

export const Contacts = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<
        'review' | 'refund' | 'technical support' | 'social networks' | 'partnerships' | 'other' | 'referral'
    >('other');

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    const renderDescription = () => {
        const descriptions = t('contactsPage.description', { returnObjects: true });

        if (Array.isArray(descriptions)) {
            return descriptions.map((description) => (
                <Col
                    onClick={() => {
                        setSelectedType(description.type);
                        setShowModal(true);
                    }}
                    lg={11}
                    sm={24}
                    key={description.title}
                    className={styles['description-container']}
                >
                    <div className="d-flex w-100 align-items-end justify-content-between">
                        <p className={styles['description-title']}>{description.title}</p>
                        <img width={120} src={`/img/${description.img}.png`} alt={description.img} />
                    </div>
                </Col>
            ));
        }

        return null;
    };

    const renderLinks = () => {
        const links: LinkItem[] = t('contactsPage.socialLinks', { returnObjects: true });

        if (Array.isArray(links)) {
            return links.map((item) => (
                <a key={item.link} className={styles.link} target="blank" href={item.link}>
                    {icons[item.icon]}
                </a>
            ));
        }

        return null;
    };

    const validate = () => {
        validateForm(['email']);
    };

    const submit = (formValue: { name: string; email: string; request: string }) => {
        setReview({
            first_name: formValue.name,
            email: formValue.email,
            type: selectedType,
            text: formValue.request,
        });
        setShowModal(false);
    };

    return (
        <div>
            <Helmet>
                <title>{t('metaTags.contactsPage.title')}</title>
                <meta name="description" content={t('metaTags.contactsPage.description')} />
                <meta name="keywords" content={t('metaTags.contactsPage.keywords')} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>
            <div className={styles.wrapper}>
                <Modal width={480} closable={false} onCancel={() => setShowModal(false)} closeIcon={null} open={showModal} footer={null}>
                    <div className={styles.modalWrapper}>
                        <p className={styles.modalTitle}>{t('contactsPage.modal.title')}</p>
                        <Form
                            form={form}
                            name="login"
                            wrapperCol={{ span: 24 }}
                            initialValues={{ name: '', email: '', request: '' }}
                            autoComplete="off"
                            onFinish={submit}
                            onChange={validate}
                            className="w-100"
                        >
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.name')}</p>
                                <Form.Item name="name" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                    <Input placeholder={t('contactsPage.modal.placeholders.name')} className="w-100" autoFocus />
                                </Form.Item>
                            </div>
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.email')}</p>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { type: 'email', message: t('rules.wrongEmail') },
                                        { required: true, message: t('rules.requiredField') },
                                    ]}
                                >
                                    <Input placeholder={t('contactsPage.modal.placeholders.email')} className="w-100" />
                                </Form.Item>
                            </div>
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.request')}</p>
                                <Form.Item name="request" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                    <TextArea placeholder={t('contactsPage.modal.placeholders.request')} rows={4} />
                                </Form.Item>
                            </div>

                            <Button htmlType="submit" className={styles.checkButton} type="primary">
                                {t('contactsPage.modal.button')}
                            </Button>
                        </Form>
                    </div>
                </Modal>
                <Row justify="space-between">
                    <Col className="mb-md-0 mb-4" lg={10} sm={24}>
                        <h1 className={styles.title}>{t('contactsPage.title')}</h1>
                        <h2 className={styles.subtitle}>{t('contactsPage.subtitle')}</h2>
                        {renderLinks()}
                    </Col>
                    <Col lg={14} sm={24}>
                        <Row justify={'space-between'} gutter={20}>
                            {renderDescription()}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
