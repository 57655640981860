/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger } from './logger';

export function openFullscreen() {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if ((elem as any).webkitRequestFullscreen) {
        /* Safari */
        (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) {
        /* IE11 */
        (elem as any).msRequestFullscreen();
    }
}

export function exitFullscreen() {
    // not a full screen now
    if (window.innerHeight !== screen.height) {
        return;
    }

    if (document.fullscreenElement) {
        document.exitFullscreen().catch((err) => Logger.error(err));
    }
}

export const dateForServer = (date: Date, withoutTime = true): string => {
    if (!date) {
        return new Date().toISOString();
    }
    if (withoutTime) {
        date.setHours(0, 0, 0, 0);
    }

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const dateFromServer = (date: Date): Date => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;

    return new Date(date.getTime() + userTimezoneOffset);
};

export const secondsAsTime = (seconds: number): string => {
    // Calculate the minutes and seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Format the minutes and seconds as strings with leading zeros if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    // Concatenate the formatted minutes and seconds into the final string
    const formattedTime = `${formattedMinutes}:${formattedSeconds}`;

    return formattedTime;
};

export const currencyFormatter = (value?: number, local = 'ru-RU'): string => {
    if (value !== 0 && (value === undefined || isNaN(value))) {
        return value === 0 ? `${value}` : '';
    }
    const formatConfig = {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0,
        currencyDisplay: 'symbol',
    };
    return Intl.NumberFormat(local, formatConfig).format(value);
};

export const groupBy = (items: any[], key: string) =>
    items.reduce(
        (result: any, item: any) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
        }),
        {},
    );

export const pluralLabel = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

export function capitalizeText(text: string): string {
    const result = text.charAt(0).toUpperCase() + text.slice(1);
    return result;
}

export const flatObject = (prefixKey: string, props?: any, separator = '_') => {
    const result: any = {};

    if (props) {
        Object.keys(props).forEach((x) => {
            result[prefixKey + separator + x] = (props as any)[x];
        });
    }

    return result;
};

export const getCoords = (elem: HTMLElement | null) => {
    if (!elem) {
        return;
    }
    // crossbrowser version
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    // const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    // const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
    // const top = box.top + scrollTop - clientTop;
    // const left = box.left + scrollLeft - clientLeft;

    const top = box.top - clientTop;
    const left = box.left - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
};

export const separateFileNameAndExtension = (fileName: string): { name: string; extension: string } => {
    const nameArr = fileName.split('.');
    const ext = nameArr.length > 1 ? (nameArr.pop() as string) : '';
    return {
        name: nameArr.length ? nameArr[0] : fileName,
        extension: ext,
    };
};
