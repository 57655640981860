import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styles from './Safety.module.scss';

export const Safety = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    const renderDescription = () => {
        const descriptions = t('safetyPage.description', { returnObjects: true });

        if (Array.isArray(descriptions)) {
            return descriptions.map((description) => (
                <Col lg={11} sm={24} key={description.title} className={styles['description-container']}>
                    <div className="d-flex flex-column">
                        <p className={styles['description-title']}>{description.title}</p>
                        <p className={styles['description-text']}>{description.text}</p>
                    </div>
                    <img src={`/img/${description.img}.png`} alt={description.img} />
                </Col>
            ));
        }

        return null;
    };

    return (
        <div>
            <Helmet>
                <title>{t('metaTags.safetyPage.title')}</title>
                <meta name="description" content={t('metaTags.safetyPage.description')} />
                <meta name="keywords" content={t('metaTags.safetyPage.keywords')} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-9KNRB6DWEV"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9KNRB6DWEV');
        `,
                    }}
                />
            </Helmet>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>{t('safetyPage.title')}</h1>
                <Row justify="space-between">{renderDescription()}</Row>
                <img className={styles.img} src="/img/girl.png" alt="girl" />
            </div>
        </div>
    );
};
